import { ReactNode, createContext } from "react";
import ErrorBoundary from "./ErrorBoundary";
import ErrorBoundaryWithBugsnag from "./ErrorBoundaryWithBugsnag";
import ErrorFallback from "./ErrorFallback";

type ErrorHandlingContextType = {
  useBugsnag: boolean;
};

export const ErrorHandlingContext =
  createContext<ErrorHandlingContextType | null>(null);

const ErrorHandlingProvider = ({
  children,
  useBugsnag,
}: {
  children: ReactNode;
  useBugsnag: boolean;
}) => (
  <ErrorHandlingContext.Provider value={{ useBugsnag }}>
    {useBugsnag ? (
      <ErrorBoundaryWithBugsnag fallbackComponent={ErrorFallback}>
        {children}
      </ErrorBoundaryWithBugsnag>
    ) : (
      <ErrorBoundary fallbackComponent={<ErrorFallback />}>
        {children}
      </ErrorBoundary>
    )}
  </ErrorHandlingContext.Provider>
);

export default ErrorHandlingProvider;
