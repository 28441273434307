import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const StyledLink = styled(Link)`
  color: inherit;
`;

const ButtonCss = css`
  ${({ theme: { colors } }) => css`
    background-color: ${colors.buttonBgPrimary};
    border: 2px solid ${colors.buttonBgPrimary};
    color: ${colors.textPrimary};

    font-family: Ringside Narrow, Cambria, Times, sans-serif;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 0.7rem;
    line-height: 1.2;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 0.1rem;
    padding: 0.225rem 0.5rem 0.25rem;

    display: block;
    width: 100%;

    &:hover {
      background-color: ${colors.buttonBgPrimaryHov};
      border-color: ${colors.buttonBorderPrimaryHov};
    }
  `};
`;

export const ButtonLink = styled(Link)`
  ${ButtonCss}
`;

export const ButtonHashLink = styled(HashLink)`
  ${ButtonCss}
`;
