import styled, { css } from "styled-components";
import { FlexRow } from "../../../components/FlexBox.styles";

export type GameRowProps = {
  greyBg?: boolean;
};

type TeamTitleProps = {
  asSubComponent?: boolean;
  grayOut: boolean;
};

export const GameRowContainer = styled.div<GameRowProps>`
  ${({ greyBg, theme: { colors } }) => css`
    width: 100%;
    padding: 0.5rem 0;

    font-family: Ringside Narrow, Cambria, Times, sans-serif;
    font-weight: 600;
    line-height: 1.3;

    :not(:last-child) {
      border-bottom: 1px solid ${colors.borderSecondary};
    }

    ${greyBg &&
    css`
      background-color: ${colors.backgroundPrimaryShadowed};
    `}
  `};
`;

export const TeamRow = styled(FlexRow)`
  padding: 0;
  flex: 1 1;
  overflow: hidden;
  flex-wrap: nowrap;
`;

export const TeamTitle = styled.span<TeamTitleProps>`
  ${({ theme: { over, breakpoints } }) => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;

    font-size: 0.75rem;
    ${over(breakpoints.s)} {
      font-size: 0.8rem;
    }
  `};
`;

const grayOutStyles = css`
  font-weight: 400;
  opacity: 0.8;
`;

export const TeamTitleLong = styled(TeamTitle)<TeamTitleProps>`
  ${({ asSubComponent, grayOut, theme: { over, breakpoints } }) => css`
    display: none;

    ${!asSubComponent &&
    css`
      ${over(breakpoints.xs)} {
        display: block;
      }
    `}

    ${grayOut && grayOutStyles}
  `};
`;

export const TeamTitleShort = styled(TeamTitle)<TeamTitleProps>`
  ${({ asSubComponent, grayOut, theme: { over, breakpoints } }) => css`
    ${over(breakpoints.xs)} {
      display: none;
    }

    ${asSubComponent &&
    css`
      ${over(breakpoints.xs)} {
        display: block;
      }
    `}

    ${grayOut && grayOutStyles}
  `};
`;

export const GroupLabel = styled.span`
  ${({ theme: { colors, breakpoints, over } }) => css`
    display: none;

    ${over(breakpoints.s)} {
      display: inline-block;
      width: 3rem;
      color: ${colors.blue1};
      font-weight: 500;
      font-size: 0.8rem;
      line-height: 1.2;
    }
  `};
`;
