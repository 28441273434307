import { ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";
import ParticipantDto from "../../../types/common/ParticipantDto";
import * as S from "./GameRow.styles";

type GameRowProps = S.GameRowProps & {
  children: ReactNode;
};

type TeamTitleProps = {
  participant: ParticipantDto;
  asSubComponent: boolean;
  grayOut?: boolean;
};

type TeamContainerProps = {
  children: ReactNode;
  alignRight?: boolean;
};

const GameRow = ({ children, greyBg }: GameRowProps) => {
  return (
    <S.GameRowContainer
      greyBg={greyBg}
      data-testid={`GameRow-Test-ID-${uuidv4()}`}
    >
      {children}
    </S.GameRowContainer>
  );
};

const TeamContainer = ({ children, alignRight }: TeamContainerProps) => (
  <S.TeamRow
    gap="0.5rem"
    alignItems="center"
    justifyContent={alignRight ? "flex-end" : "flex-start"}
  >
    {children}
  </S.TeamRow>
);

const TeamTitle = ({
  participant,
  asSubComponent,
  grayOut,
}: TeamTitleProps) => (
  <>
    <S.TeamTitleShort
      asSubComponent={asSubComponent}
      grayOut={grayOut !== undefined ? grayOut : false}
    >
      {participant.abbreviation}
    </S.TeamTitleShort>
    <S.TeamTitleLong
      asSubComponent={asSubComponent}
      grayOut={grayOut !== undefined ? grayOut : false}
    >
      {participant.label}
    </S.TeamTitleLong>
  </>
);

const GroupLabel = ({ children }: { children?: ReactNode }) => (
  <S.GroupLabel>{children}</S.GroupLabel>
);

GameRow.TeamContainer = TeamContainer;
GameRow.TeamTitle = TeamTitle;
GameRow.GroupLabel = GroupLabel;

export default GameRow;
