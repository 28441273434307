import { useContext } from "react";
import { EmbeddedContext } from "../App";
import { DisplayAdsDesktopTablet, DisplayAdsMew } from "./Ads";
import { FlexCol, FlexRow } from "./FlexBox.styles";

type Props = {
  children?: React.ReactNode;
};

const PageBody = ({ children }: Props) => {
  return <FlexRow fluid>{children}</FlexRow>;
};

PageBody.Section = function PageBodySection({ children }: Props) {
  const embedding = useContext(EmbeddedContext);
  const inApp = embedding?.isInKickerApp;

  return inApp ? (
    <FlexCol fluid style={{ padding: "0.5rem" }}>
      {children}
    </FlexCol>
  ) : (
    <FlexCol
      as="section"
      width="66.6665%"
      padding={!inApp}
      flexGrow
      flexGrowOnMobile
      sectionBorder={!inApp}
    >
      {children}
    </FlexCol>
  );
};

PageBody.Aside = function PageBodyAside({ children }: Props) {
  return (
    <FlexCol
      as="aside"
      asideBorder
      flexGrow
      width="33.3335%"
      minWidth="300px"
      padding
    >
      <DisplayAdsMew />
      <DisplayAdsDesktopTablet />
      {children}
    </FlexCol>
  );
};

export default PageBody;
