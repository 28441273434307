enum MatchStatus {
  NotStarted = "notstarted",
  InProgress = "inprogress",
  Finished = "finished",
  Interrupted = "interrupted",
  Deleted = "deleted",
  Unknown = "unknown",
}

export default MatchStatus;
