import styled, { css } from "styled-components";
import { H3 } from "../../../components/Headings.styles";

type SharedCellProps = {
  alignLeft?: boolean;
  bolder?: boolean;
  color?: "grey" | "brand";
  greyBg?: boolean;
  hideOnDesktop?: boolean;
  hideOnMobile?: boolean;
  sticky?: boolean;
  upperCase?: boolean;
};

type RowProps = {
  greyBg?: boolean;
  treshholdBorder?: boolean;
};

const padding = css`
  padding: 0.5em 0.25em;
`;

const noWrap = css`
  white-space: nowrap;
`;

const alignLeft = css<SharedCellProps>`
  text-align: ${({ alignLeft }) => (alignLeft ? "left" : "inherit")};
`;

const bolder = css<SharedCellProps>`
  ${({ bolder }) =>
    bolder &&
    css`
      font-weight: 600;
    `}
`;

const hideOnDesktop = css<SharedCellProps>`
  ${({ hideOnDesktop, theme: { over, breakpoints } }) =>
    hideOnDesktop &&
    css`
      ${over(breakpoints.l)} {
        display: none;
      }
    `}
`;

const hideOnMobile = css<SharedCellProps>`
  ${({ hideOnMobile, theme: { under, breakpoints } }) =>
    hideOnMobile &&
    css`
      ${under(breakpoints.l)} {
        display: none;
      }
    `}
`;

const sticky = css<SharedCellProps>`
  ${({ sticky, theme: { colors } }) =>
    sticky
      ? css`
          background-color: ${colors.white};
          position: sticky;
          left: 0;
          top: 0;
          z-index: 2;
        `
      : css`
          position: relative;
          z-index: 1;
        `}
`;

const color = css<SharedCellProps>`
  ${({ theme: { colors }, color }) => css`
    color: ${color === "grey"
      ? colors.textSecondary
      : color === "brand"
      ? colors.brandPrimary
      : "inherit"};
  `};
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1rem;
`;

export const TableHeadlineWeb = styled(H3)`
  margin-top: 1.5rem;
`;

export const TableHeadlineApp = styled(H3)`
  margin-top: 1rem;
`;

const upperCase = css<SharedCellProps>`
  ${({ upperCase }) =>
    upperCase &&
    css`
      text-transform: uppercase;
    `}
`;

export const RankingTable = styled.table`
  width: 100%;
  font-family: Ringside Narrow, Cambria, Times, sans-serif;
  text-align: right;
  font-variant-numeric: tabular-nums;
  border-collapse: collapse;
  border-spacing: 0;
`;

export const Row = styled.tr<RowProps>`
  ${({ greyBg, treshholdBorder, theme: { colors, iPhoneOnly } }) => css`
    background-color: ${greyBg
      ? colors.backgroundPrimaryShadowed
      : colors.backgroundPrimary};

    border-bottom: 0.5px solid
      ${treshholdBorder ? colors.grey5 : colors.borderSecondary};

    ${iPhoneOnly} {
      border-bottom: 1.5px solid
        ${treshholdBorder ? colors.grey5 : colors.borderSecondary};
    }
  `};
`;

const BaseCell = css<SharedCellProps>`
  ${({ theme: { colors } }) => css`
    ${padding}
    ${noWrap}
    ${alignLeft}
    ${bolder}
    ${color}
    ${hideOnDesktop}
    ${hideOnMobile}
    ${sticky}
    ${upperCase}
    background-color: ${colors.backgroundPrimary};
    background-clip: padding-box;
  `};
`;

export const Cell = styled.td<SharedCellProps>`
  ${BaseCell}
  font-size: 0.8rem;
`;

export const NumberCell = styled(Cell)`
  font-family: Ringside Condensed, Arial Narrow, sans-serif;
`;

export const HeadCell = styled.th<SharedCellProps>`
  ${BaseCell}

  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.2;
  border-bottom: 2px solid ${({ theme }) => theme.colors.brandPrimary};
  background-clip: padding-box;
`;

export const HeadCellIcon = styled.span`
  color: ${({ theme }) => theme.colors.brandPrimary};
  line-height: 1rem;
  width: 1rem;
  padding-left: 0.25rem;
`;

export const Image = styled.img`
  max-width: 30px;
  max-height: 30px;
`;
