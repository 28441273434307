import { createContext } from "react";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./design/theme";
import useColorScheme from "./hooks/useColorScheme";
import { containsAppParamter, isDarkFromApp } from "./lib/theme-helper";
import AppContainer from "./App.styles";
import "./assets/css/globals.css";

type EmbeddedContextType = {
  isInKickerApp: boolean;
};

export const EmbeddedContext = createContext<EmbeddedContextType | null>(null);

type AppProps = {
  children: JSX.Element;
};

const App = ({ children }: AppProps) => {
  const url = location.href;
  let theme = lightTheme;
  const isDarkFromSystem = useColorScheme("dark");

  const isInKickerApp = containsAppParamter(url);

  if (isInKickerApp) {
    theme = isDarkFromApp(url) ? darkTheme : lightTheme;
  } else {
    theme = isDarkFromSystem ? darkTheme : lightTheme;
  }

  return (
    <ThemeProvider theme={theme}>
      <EmbeddedContext.Provider value={{ isInKickerApp }}>
        <AppContainer>{children}</AppContainer>
      </EmbeddedContext.Provider>
    </ThemeProvider>
  );
};

export default App;
