import styled, { css } from "styled-components";

type ScoreHolderProps = {
  live?: boolean;
};

const borderRadius = "0.12em";

const baseWidth = css`
  width: 5rem;
`;

const baseFontStyles = css`
  font-family: Ringside Narrow, Cambria, Times, sans-serif;
  font-weight: 600;
  font-variant-numeric: lining-nums;
`;

export const ScoreBoard = styled.div`
  ${baseWidth}
`;

export const ScoreHolder = styled.div<ScoreHolderProps>`
  ${baseFontStyles}
  font-size: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};

  ${({ theme: { colors }, live }) => css`
    background: ${colors.scoreBoardBgPrimary};
    color: ${live ? colors.yellow : colors.grey0};
  `};
`;

export const TimeInScoreHolder = styled(ScoreHolder)`
  color: ${({ theme }) => theme.colors.yellow};
  font-size: 0.6em;
  text-align: center;
  white-space: pre-line;
  padding: 0.5em;
`;

export const DateBoard = styled.div`
  ${baseWidth}
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const DateHolder = styled.span`
  font-size: 0.8em;
`;

export const TimeHolder = styled.span`
  font-size: 0.7em;
  font-weight: 400;
`;

export const SubScoreHolder = styled.div`
  ${baseFontStyles}
  font-size: 0.7em;
  line-height: 1em;
  text-align: center;
  padding: 0.2em 0;

  ${({ theme: { colors } }) => css`
    background: ${colors.scoreBoardBgSecondary};
    color: ${colors.textSecondary};
  `};
`;
