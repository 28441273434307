export const DisplayAdsMew = () => (
  <div
    className="kick__ad-pos_wrapper"
    data-pagetype="subsite_half"
    data-pos="mrec_1"
  >
    <div
      id="kick__ad-mrec_1_mew"
      className="kick__global__display--none"
      data-device="mew"
    ></div>
  </div>
);

export const DisplayAdsDesktopTablet = () => (
  <div
    className="kick__ad-pos_wrapper"
    data-pagetype="subsite_half"
    data-pos="mrec_1"
  >
    <div
      id="kick__ad-mrec_1_desktop"
      className="kick__global__display--none"
      data-device="desktop"
    ></div>
    <div
      id="kick__ad-mrec_1_tablet"
      className="kick__global__display--none"
      data-device="tablet"
    ></div>
  </div>
);
