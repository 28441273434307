import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import GameDayResponseDto from "../types/game-day/GameDayResponseDto";
import RankingTableResponse from "../types/ranking-table/RankingTableResponse";
import SelectionDto from "../types/common/SelectionDto";
import {
  getAvailableSeason,
  getGameDay,
  getRankingTablePreview,
} from "../lib/apiCalls";
import { EmbeddedContext } from "../App";
import { CallbacksContext } from "../RouterWrapper";
import PageBody from "../components/PageBody";
import PageHeader from "../components/PageHeader/PageHeader";
import DropDown from "../components/DropDown/DropDown";
import { HeadlineRow } from "../components/Page.styles";
import { FlexCol, FlexRow } from "../components/FlexBox.styles";
import { H2 } from "../components/Headings.styles";
import LoadingSpinner from "../components/LoadingSpinner.styles";
import Logo from "../components/Logo/Logo";
import GameDay from "../features/game-day/GameDay/GameDay";
import RankingTables from "../features/ranking-table/RankingTables/RankingTables";
import Pagination from "../features/game-day/Pagination/Pagination";
import ErrorFallback from "../features/error-handling/ErrorFallback";
import ShowGameDay from "../types/enums/showGameDayEnum";
import RankingTablePreviewResponse from "../types/ranking-table/RankingTableResponse";
import { BreadCrumbsWrapperElement } from "../components/PageHeader/PageHeader.styles";
import Divider from "../components/Divider.styles";
import Module from "../components/Module.styles";

interface Filters {
  seasonFilter: string | undefined;
  phaseFilter: string | undefined;
  stageFilter: string | undefined;
  gameDayFilter: string | undefined;
}

type Params = {
  widgetPrefix?: string;
  tournament?: string;
  seasonFilter?: string;
  phaseFilter?: string;
  stageFilter?: string;
  gameDayFilter?: string;
};

type HeaderProps = {
  isLoading: boolean;
  availableSeasons: SelectionDto;
  gdData: GameDayResponseDto;
  initParams: Params;
  changeFilter: ChangeFilterFunc;
};

type ChangeFilterFunc = (
  changedSeasonFilter?: string,
  changedPhaseFilter?: string,
  changedStageFilter?: string,
  changedGameDayFilter?: string
) => void;

const AppHeader = ({
  gdData,
  isLoading,
  availableSeasons,
  changeFilter,
}: HeaderProps) => (
  <FlexCol style={{ flexWrap: "nowrap", padding: "0 0.5rem" }}>
    {!isLoading ? (
      <FlexRow
        fluid
        justifyContent="center"
        gap="0.5rem"
        style={{ flexWrap: "nowrap", padding: "0.75rem 0" }}
      >
        <FlexRow fluid>
          {availableSeasons && (
            <DropDown
              variant="app"
              label={availableSeasons.label}
              options={availableSeasons.options}
              selected={gdData.selectedSeason}
              setSelected={(arg) => changeFilter(arg)}
              dataTestId="season-dropdown"
            />
          )}
        </FlexRow>
        <Divider orientation="vertical" />
        <FlexRow fluid justifyContent="flex-end">
          {gdData.availablePhases.options?.length > 0 && (
            <DropDown
              variant="app"
              label={gdData.availablePhases.label}
              options={gdData.availablePhases.options}
              selected={gdData.selectedPhase}
              setSelected={(arg) =>
                changeFilter(gdData.selectedSeason, arg, undefined, undefined)
              }
              dataTestId="phase-dropdown"
            />
          )}
        </FlexRow>
      </FlexRow>
    ) : null}
    <Divider />
  </FlexCol>
);

const WebHeader = ({
  initParams,
  gdData,
  isLoading,
  availableSeasons,
  changeFilter,
}: HeaderProps) => (
  <PageHeader>
    {initParams.tournament && (
      <Logo
        iconName={initParams.tournament}
        label={gdData.tournamentLabel}
        glowOnDarkMode={gdData.tournamentLogoGlowOnDarkMode}
      />
    )}
    <PageHeader.BreadCrumbsWrapperContainer>
      <PageHeader.BreadCrumbsWrapperFirstLine>
        {gdData.tournamentLabel && (
          <PageHeader.Title
            titleItemForDesktop={gdData.tournamentLabel}
            titleItem="Spieltag"
          />
        )}
        {!isLoading && availableSeasons && (
          <DropDown
            label={availableSeasons.label}
            options={availableSeasons.options}
            selected={gdData.selectedSeason}
            setSelected={(arg) => changeFilter(arg)}
            dataTestId="season-dropdown"
          />
        )}
      </PageHeader.BreadCrumbsWrapperFirstLine>

      {!isLoading && (
        <PageHeader.BreadCrumbsWrapperSecondLine>
          {gdData.availablePhases.options?.length > 0 && (
            <BreadCrumbsWrapperElement>
              <DropDown
                label={gdData.availablePhases.label}
                options={gdData.availablePhases.options}
                selected={gdData.selectedPhase}
                setSelected={(arg) =>
                  changeFilter(gdData.selectedSeason, arg, undefined, undefined)
                }
                dataTestId="phase-dropdown"
              />
            </BreadCrumbsWrapperElement>
          )}

          {gdData.availableGameDays.options?.length > 0 && (
            <DropDown
              label={gdData.availableGameDays.label}
              options={gdData.availableGameDays.options}
              selected={gdData.selectedGameDay.gameDayKey}
              setSelected={(arg) =>
                changeFilter(
                  gdData.selectedSeason,
                  gdData.selectedPhase,
                  gdData.selectedStage,
                  arg
                )
              }
              dataTestId="gamedays-dropdown"
            />
          )}
        </PageHeader.BreadCrumbsWrapperSecondLine>
      )}
    </PageHeader.BreadCrumbsWrapperContainer>
  </PageHeader>
);

const GameDayPage = () => {
  const callbacks = useContext(CallbacksContext);
  const embedding = useContext(EmbeddedContext);
  const inApp = embedding?.isInKickerApp;
  const navigate = useNavigate();
  const { state } = useLocation();

  const initParams = useParams<Params>();

  const [pageState, setPageState] = useState({
    init: true,
    availableSeasons: {} as SelectionDto,
    gdData: {} as GameDayResponseDto,
    rtData: {} as RankingTableResponse,
    isLoading: true,
    error: null as Error | null,
    filters: {
      seasonFilter: initParams.seasonFilter,
      phaseFilter: initParams.phaseFilter,
      stageFilter: initParams.stageFilter,
      gameDayFilter: initParams.gameDayFilter,
    } as Filters,
  });

  const { availableSeasons, gdData, rtData, isLoading, error } = pageState;

  const changeFilter: ChangeFilterFunc = (
    changedSeasonFilter,
    changedPhaseFilter,
    changedStageFilter,
    changedGameDayFilter
  ) => {
    const currentParams = new Array<string>();

    if (changedSeasonFilter) {
      currentParams.push(changedSeasonFilter);
      if (changedPhaseFilter) {
        currentParams.push(changedPhaseFilter);
        if (changedStageFilter) {
          currentParams.push(changedStageFilter);
          if (changedGameDayFilter) {
            currentParams.push(changedGameDayFilter);
          }
        }
      }
    }

    const uri = `/${initParams.widgetPrefix}/${
      initParams.tournament
    }/spieltag/${currentParams.join("/")}`;
    navigate(uri, { replace: true });
  };

  const { seasonFilter, phaseFilter, stageFilter, gameDayFilter } =
    pageState.filters;

  useEffect(() => {
    const fetchData = async () => {
      setPageState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      try {
        if (callbacks !== null) {
          const { callbackMethod, errorCallbackMethod } = callbacks;

          const [availableSeasons, gameDayResponse] = await Promise.all([
            await getAvailableSeason(
              errorCallbackMethod,
              initParams.tournament,
              initParams.seasonFilter
            ),
            await getGameDay(
              errorCallbackMethod,
              initParams.tournament,
              initParams.seasonFilter,
              initParams.phaseFilter,
              initParams.stageFilter,
              initParams.gameDayFilter,
              state?.showGameDay ?? ShowGameDay.Current
            ),
          ]);

          const newFilters = {
            seasonFilter: gameDayResponse.selectedSeason,
            phaseFilter: gameDayResponse.selectedPhase,
            stageFilter: gameDayResponse.selectedStage,
            gameDayFilter: gameDayResponse.selectedGameDay.gameDayKey,
          };

          const rankingTableResponse = await getRankingTablePreview(
            errorCallbackMethod,
            initParams.tournament,
            newFilters.seasonFilter,
            newFilters.phaseFilter,
            newFilters.stageFilter
          );

          callbackMethod(
            "esport-widget",
            pageState.init ? "page-init" : "page-change",
            [
              {
                name: "prefix",
                value: initParams.widgetPrefix,
                label: "eSport",
              },
              {
                name: "tournament",
                value: gameDayResponse.tournamentKey,
                label: gameDayResponse.tournamentLabel,
              },
              { name: "page", value: "spieltag", label: "Spieltag" },
              {
                name: "season",
                value: gameDayResponse.selectedSeason,
                label: availableSeasons.label,
              },
              {
                name: "phase",
                value: gameDayResponse.selectedPhase,
                label: gameDayResponse.availablePhases.label,
              },
              {
                name: "stage",
                value: gameDayResponse.selectedStage,
                label: gameDayResponse.availableStages?.label ?? "alle",
              },
              {
                name: "gameDay",
                value: gameDayResponse.selectedGameDay?.gameDayKey,
                label: `${gameDayResponse.selectedGameDay?.gameDayName}`,
              },
            ]
          );

          setPageState((prev) => ({
            ...prev,
            availableSeasons: availableSeasons,
            gdData: gameDayResponse as GameDayResponseDto,
            rtData: rankingTableResponse as RankingTablePreviewResponse,
            init: false,
            isLoading: false,
            filters: newFilters,
          }));
        }
      } catch (err) {
        setPageState((prev) => ({
          ...prev,
          error:
            err instanceof Error ? err : new Error("An unknown error occurred"),
        }));
      }
    };

    fetchData();
  }, [initParams]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      {inApp ? (
        <AppHeader
          isLoading={isLoading}
          availableSeasons={availableSeasons}
          gdData={gdData}
          changeFilter={changeFilter}
          initParams={initParams}
        />
      ) : (
        <WebHeader
          isLoading={isLoading}
          availableSeasons={availableSeasons}
          gdData={gdData}
          changeFilter={changeFilter}
          initParams={initParams}
        />
      )}
      <PageBody>
        <PageBody.Section>
          {!inApp && (
            <HeadlineRow
              fluid
              alignItems="center"
              justifyContent="space-between"
            >
              <H2 uppercase>Begegnungen</H2>
              {gdData?.availableStages?.options?.length > 0 && (
                <DropDown
                  variant="button"
                  label={gdData.availableStages.label}
                  options={gdData.availableStages.options}
                  selected={gdData.selectedStage}
                  setSelected={(arg) =>
                    changeFilter(seasonFilter, phaseFilter, arg, gameDayFilter)
                  }
                  dataTestId="divisions-dropdown"
                />
              )}
            </HeadlineRow>
          )}

          {isLoading ? (
            <FlexRow fluid padding alignItems="center" justifyContent="center">
              <LoadingSpinner />
            </FlexRow>
          ) : (
            <>
              <Module size={inApp ? "small" : "medium"}>
                <Pagination
                  tournament={gdData.tournamentKey}
                  season={gdData.selectedSeason}
                  selectedStage={gdData.selectedStage}
                  selectedGameDay={gdData.selectedGameDay.gameDayKey}
                  availableGameDays={gdData.availableGameDays.options.map(
                    (x) => x.value
                  )}
                  availablePhases={gdData.availablePhases.options.map(
                    (x) => x.value
                  )}
                  selectedPhase={gdData.selectedPhase}
                >
                  {inApp && gdData.availableGameDays.options?.length > 0 && (
                    <DropDown
                      variant="app"
                      label={gdData.availableGameDays.label}
                      options={gdData.availableGameDays.options}
                      selected={gdData.selectedGameDay.gameDayKey}
                      setSelected={(arg) =>
                        changeFilter(
                          seasonFilter,
                          phaseFilter,
                          stageFilter,
                          arg
                        )
                      }
                      dataTestId="gamedays-dropdown"
                    />
                  )}
                </Pagination>
              </Module>
              {inApp && <Divider style={{ padding: ".5rem" }} />}
              <GameDay data={gdData} />
              {!inApp && (
                <Pagination
                  tournament={gdData.tournamentKey}
                  season={gdData.selectedSeason}
                  selectedStage={gdData.selectedStage}
                  selectedGameDay={gdData.selectedGameDay.gameDayKey}
                  availableGameDays={gdData.availableGameDays.options.map(
                    (x) => x.value
                  )}
                  availablePhases={gdData.availablePhases.options.map(
                    (x) => x.value
                  )}
                  selectedPhase={gdData.selectedPhase}
                />
              )}
            </>
          )}
        </PageBody.Section>
        {!inApp && (
          <PageBody.Aside>
            <HeadlineRow fluid noBottomMargin>
              <H2 uppercase>Tabelle{rtData?.tables?.length > 1 && "n"}</H2>
            </HeadlineRow>
            <FlexCol fluid alignItems="flex-start">
              <RankingTables data={rtData} smallVariant />
            </FlexCol>
          </PageBody.Aside>
        )}
      </PageBody>
    </>
  );
};

export default GameDayPage;
