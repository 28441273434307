import { useContext, useEffect, useState } from "react";
import { FlexCol, FlexRow } from "../../components/FlexBox.styles";
import { H1 } from "../../components/Headings.styles";
import { StatisticsText } from "../../components/Text.styles";
import { CallbacksContext } from "../../RouterWrapper";
import { getAvailableTournaments } from "../../lib/apiCalls";
import TournamentDto from "../../types/common/TournamentDto";
import LoadingSpinner from "../../components/LoadingSpinner.styles";
import Tournaments from "../../components/Tournaments/Tournaments";
import logError from "../../lib/logError";

type ErrorFallbackState = {
  availableTournaments: TournamentDto[];
  isLoading: boolean;
  hasError: boolean;
};

type ErrorFallbackProps = {
  error?: Error;
};

const ErrorFallback = ({ error }: ErrorFallbackProps) => {
  const [state, setState] = useState<ErrorFallbackState>({
    availableTournaments: {} as TournamentDto[],
    isLoading: true,
    hasError: false,
  });

  const { isLoading, availableTournaments } = state;
  const callbacks = useContext(CallbacksContext);

  useEffect(() => {
    if (error) logError(error);
  }, [error]);

  useEffect(() => {
    const fetchData = async () => {
      setState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      if (callbacks?.errorCallbackMethod) {
        try {
          const availableTournaments = await getAvailableTournaments(
            callbacks.errorCallbackMethod
          );

          setState((prev) => ({
            ...prev,
            availableTournaments,
            isLoading: false,
          }));
        } catch (error) {
          setState((prev) => ({
            ...prev,
            availableTournaments,
            isLoading: false,
            hasError: true,
          }));
        }
      }
    };

    fetchData();
  }, []);

  return (
    <FlexCol
      role="alert"
      fluid
      padding
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      gap="1rem"
    >
      <H1>
        Hoppla!
        <br />
        Da ist wohl etwas schief gelaufen...
      </H1>
      {!state.hasError ? (
        <>
          <StatisticsText>
            Versuche es mal mit folgenden Seiten:{" "}
          </StatisticsText>
          <FlexRow justifyContent="center" alignItems="center">
            {isLoading ? (
              <FlexRow fluid alignItems="center" justifyContent="center">
                <LoadingSpinner />
              </FlexRow>
            ) : (
              <Tournaments tournaments={availableTournaments} />
            )}
          </FlexRow>
        </>
      ) : (
        <StatisticsText>Versuche es später noch einmal.</StatisticsText>
      )}
    </FlexCol>
  );
};

export default ErrorFallback;
