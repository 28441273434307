import styled, { css } from "styled-components";
import { FlexRow } from "../components/FlexBox.styles";

export const HeadlineRow = styled(FlexRow)<{ noBottomMargin?: boolean }>`
  margin: 0.5rem 0 1rem 0;

  ${({ noBottomMargin }) =>
    noBottomMargin &&
    css`
      margin-bottom: 0;
    `};
`;

export const SubNavigationArea = styled.div<{
  hideOnDesktop?: boolean;
  hideOnMobile?: boolean;
}>`
  ${({
    theme: { over, under, breakpoints },
    hideOnDesktop,
    hideOnMobile,
  }) => css`
    width: 100%;

    ${hideOnDesktop &&
    css`
      ${over(breakpoints.m)} {
        display: none;
      }
    `}

    ${hideOnMobile &&
    css`
      ${under(breakpoints.m)} {
        display: none;
      }
    `}
  `};
`;
