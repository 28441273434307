import { useState } from "react";
import OptionDto from "../../types/common/OptionDto";
import { HeadBreadCrumbItemSmaller } from "../../components/Headings.styles";
import * as S from "./DropDown.styles";

interface Props {
  options: OptionDto[];
  selected: string;
  setSelected: (value: string) => void;
  label: string;
  variant?: "breadcrumb" | "button" | "app";
  dataTestId?: string;
}

const VARIANT_MAP = {
  breadcrumb: {
    Dropdown: S.DropdownBreadCrumb,
    Label: S.LabelBreadCrumb,
    Signifier: S.SignifierBreadCrumb,
  },
  button: {
    Dropdown: S.DropdownButton,
    Label: S.LabelButton,
    Signifier: S.SignifierButton,
  },
  app: {
    Dropdown: S.DropdownApp,
    Label: S.LabelApp,
    Signifier: S.SignifierApp,
  },
};

const DropDown = ({
  options = [],
  setSelected,
  label: dropdownLabel,
  selected,
  variant = "breadcrumb",
  dataTestId,
}: Props) => {
  const [value, setValue] = useState(selected);

  const handleChange = (event: { target: { value: string } }) => {
    setValue(event.target.value);
    setSelected(event.target.value);
  };

  const {
    Dropdown: CurrentDropdown,
    Label: CurrentLabel,
    Signifier: CurrentSignifier,
  } = VARIANT_MAP[variant];

  if (options.length <= 1) {
    return (
      <HeadBreadCrumbItemSmaller>{dropdownLabel}</HeadBreadCrumbItemSmaller>
    );
  }

  return (
    <CurrentDropdown>
      <S.LabelWrapper>
        <CurrentLabel>{dropdownLabel}</CurrentLabel>
        <CurrentSignifier className="icon-DropDown kick__icon-DropDown" />
      </S.LabelWrapper>

      <S.Select data-testid={dataTestId} value={value} onChange={handleChange}>
        {options.map((option: OptionDto) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </S.Select>
    </CurrentDropdown>
  );
};

export default DropDown;
