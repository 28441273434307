import styled, { css } from "styled-components";
import { FlexRow } from "../FlexBox.styles";

const commonBorderStyles = css`
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderSecondary};
    padding-bottom: 1rem;
  }
`;

export const TournamentsRow = styled.div`
  font-family: Ringside Narrow, Cambria, Times, sans-serif;
  width: 100%;
  display: grid;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;

  ${commonBorderStyles}

  ${({ theme: { over, breakpoints } }) => css`
    ${over(breakpoints.m)} {
      grid-template-columns: 2fr 1fr 1fr 1fr;
    }
  `};
`;

export const TournamentsRowLabel = styled(FlexRow)`
  ${commonBorderStyles}

  ${({ theme: { over, breakpoints } }) => css`
    ${over(breakpoints.m)} {
      :not(:last-child) {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  `};
`;

export const TournamentsRowNavElement = styled.div`
  padding-left: 3rem;

  ${commonBorderStyles}

  ${({ theme: { colors, over, breakpoints } }) => css`
    ${over(breakpoints.m)} {
      padding-left: 0;

      :not(:last-child) {
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    & > a {
      color: inherit;
      text-decoration: none;
      transition: 0.2s ease-in-out;

      &:hover {
        color: ${colors.brandPrimary};
      }
    }
  `};
`;

export const LinkForApp = styled.a`
  text-decoration: none;
  color: inherit;
  padding: 0 0.5rem;
`;
