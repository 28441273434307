import { Fragment, useContext } from "react";
import GameDayResponseDto from "../../../types/game-day/GameDayResponseDto";
import GameListDto from "../../../types/game-day/GameListDto";
import GameList from "../GameList/GameList";
import { Link } from "../../../components/Link/Link";
import { StatisticsText } from "../../../components/Text.styles";
import Module from "../../../components/Module.styles";
import Divider from "../../../components/Divider.styles";
import { useBuildUrl } from "../../../hooks/useBuildUrl";
import { EmbeddedContext } from "../../../App";

type Props = {
  data: GameDayResponseDto;
  asSubComponent?: boolean;
};

const GameDay = (props: Props) => {
  const embedding = useContext(EmbeddedContext);
  const inApp = embedding?.isInKickerApp;
  const { data, asSubComponent } = props;
  const { selectedGameDay, message } = data;

  const gamedayUrl = useBuildUrl("spieltag");

  return (
    <>
      {selectedGameDay.gameLists.length > 0 ? (
        <>
          {selectedGameDay.gameLists &&
            selectedGameDay.gameLists.map((gameList: GameListDto, index) => (
              <Fragment key={`${gameList.label}${index}`}>
                <Module>
                  <GameList
                    gameList={gameList}
                    withFoldingScoreBoard={true}
                    asSubComponent={asSubComponent}
                  />
                </Module>
                {inApp && index !== selectedGameDay.gameLists.length - 1 && (
                  <Module>
                    <Divider />
                  </Module>
                )}
              </Fragment>
            ))}
          {/* {asSubComponent && (
            <Link variant="button" to={gamedayUrl} reloadDocument>
              Spieltag - {selectedGameDay.gameDayName}
            </Link>
          )} */}
        </>
      ) : (
        <StatisticsText>
          {message
            ? message
            : "Aktuell stehen für den gewünschten Bereich noch keine Daten zur Verfügung."}
        </StatisticsText>
      )}
    </>
  );
};
export default GameDay;
