import styled, { css } from "styled-components";

type SharedProps = {
  fluid?: boolean;
  padding?: boolean;
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between";
  alignItems?: "flex-start" | "flex-end" | "center" | "space-between";
  gap?: string;
};

type FlexColProps = {
  dontShowOnMobile?: boolean;
  flexGrow?: boolean;
  minWidth?: string;
  width?: string;
  flexGrowOnMobile?: boolean;
  showMobileOnly?: boolean;
  sectionBorder?: boolean;
  asideBorder?: boolean;
  textAlign?: "center";
};

type FlexRowProps = {
  noWrap?: boolean;
  reverse?: boolean;
};

const FlexBox = styled.div<SharedProps>`
  ${({
    theme: { over, breakpoints },
    padding,
    fluid,
    justifyContent,
    alignItems,
    gap,
  }) => css`
    display: flex;

    ${fluid &&
    css`
      width: 100%;
    `}

    ${padding &&
    css`
      padding: 1rem 0.5rem;

      ${over(breakpoints.l)} {
        padding: 1rem 2rem;
      }
    `}

    ${gap &&
    css`
      gap: ${gap};
    `}

    ${justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

    ${alignItems &&
    css`
      align-items: ${alignItems};
    `}
  `};
`;

export const FlexCol = styled(FlexBox)<FlexColProps>`
  ${({
    theme: { colors, over, under, breakpoints },
    flexGrow,
    minWidth,
    width,
    flexGrowOnMobile,
    dontShowOnMobile,
    showMobileOnly,
    sectionBorder,
    asideBorder,
    textAlign,
  }) => css`
    flex-flow: column wrap;

    ${flexGrow &&
    css`
      flex-grow: 1;
    `}

    ${minWidth &&
    css`
      min-width: ${minWidth};
    `}

    ${width &&
    css`
      width: ${width};
    `}

    ${flexGrowOnMobile &&
    css`
      ${under(breakpoints.m)} {
        flex-grow: 1;
      }
    `}

    ${dontShowOnMobile &&
    css`
      ${under(breakpoints.m)} {
        display: none;
      }
    `}

    ${showMobileOnly &&
    css`
      display: none;

      ${under(breakpoints.m)} {
        display: flex;
      }
    `}

    ${sectionBorder &&
    css`
      border-bottom: 1px solid ${colors.borderPrimary};

      ${over(breakpoints.m)} {
        border-bottom: 0;
        border-right: 1px solid ${colors.borderPrimary};
      }
    `} 

    ${asideBorder &&
    css`
      ${over(breakpoints.m)} {
        margin-left: -1px;
        border-left: 1px solid ${colors.borderPrimary};
      }
    `} 
    
    ${textAlign &&
    css`
      text-align: ${textAlign};
    `}
  `};
`;

export const FlexRow = styled(FlexBox)<FlexRowProps>`
  ${({ theme: { under, breakpoints }, noWrap, reverse }) => css`
    ${noWrap &&
    css`
      flex-wrap: nowrap;
    `}

    ${under(breakpoints.m)} {
      flex-flow: row ${reverse && "-reverse"} wrap;
    }

    ${reverse &&
    css`
      flex-direction: row-reverse;
    `}
  `};
`;
