import styled, { css } from "styled-components";
import { H1 } from "../Headings.styles";

export const PageHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.25rem 0.5rem;
  white-space: nowrap;

  ${({ theme: { colors, over, breakpoints } }) => css`
    border-bottom: 1px solid ${colors.borderPrimary};

    ${over(breakpoints.m)} {
      padding: 1rem 0.5rem;
    }

    ${over(breakpoints.l)} {
      padding: 1rem 2rem;
    }
  `};
`;

export const Title = styled(H1)`
  ${({ theme: { under, breakpoints } }) => css`
    ${under(breakpoints.m)} {
      font-size: 1rem;
    }
  `};
`;

export const TitleItemForDesktop = styled.span`
  ${({ theme: { colors, under, breakpoints } }) => css`
    margin-right: 0.75em;
    position: relative;

    &:after {
      content: "";
      border-bottom: 2px solid ${colors.grey5};
      width: 10px;
      position: absolute;
      top: 22px;
      right: -17px;
    }

    ${under(breakpoints.m)} {
      display: none;
    }
  `};
`;

const gapStyles = css`
  gap: 0.5rem;

  ${({ theme: { over, breakpoints } }) => css`
    ${over(breakpoints.m)} {
      gap: 1rem;
    }
  `};
`;

export const BreadCrumbsWrapperContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${gapStyles}

  ${({ theme: { over, breakpoints } }) => css`
    ${over(breakpoints.s)} {
      flex-direction: row;
    }
  `};
`;

export const BreadCrumbsWrapperFirstLine = styled.div`
  display: flex;
  gap: 1rem;
`;

export const BreadCrumbsWrapperSecondLine = styled.div`
  display: flex;
  flex-direction: column;
  ${gapStyles}

  ${({ theme: { over, breakpoints } }) => css`
    ${over(breakpoints.xxs)} {
      flex-direction: row;
      flex-wrap: wrap;
    }
  `};
`;

export const BreadCrumbsWrapperElement = styled.div`
  max-width: 100%;

  ${({ theme: { over, breakpoints } }) => css`
    ${over(breakpoints.xxs)} {
      max-width: 50%;
    }

    ${over(breakpoints.l)} {
      max-width: 100%;
    }
  `};
`;

export const BreadCrumbsWrapperColRow = styled.div`
  display: flex;
  flex-direction: column;
  ${gapStyles}

  ${({ theme: { over, breakpoints } }) => css`
    ${over(breakpoints.m)} {
      flex-direction: row;
    }
  `};
`;

export const BreadCrumbsWrapperRow = styled.div`
  display: flex;
  ${gapStyles}
`;
