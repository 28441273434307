import { HttpStatusCode } from "axios";
import RankingTableResponse from "../types/ranking-table/RankingTableResponse";
import ErrorDto from "../types/callbacks/ErrorDto";
import createQuery from "./createQuery";
import fetchResult from "./fetchBffApi";
import GameDayResponseDto from "../types/game-day/GameDayResponseDto";
import SelectionDto from "../types/common/SelectionDto";
import TournamentDto from "../types/common/TournamentDto";
import ShowGameDay from "../types/enums/showGameDayEnum";
import RankingTablePreviewResponse from "../types/ranking-table/RankingTableResponse";

const handleApiError = async <T>(
  result: T | Error,
  errorCallbackMethod: (status: number, message: string) => ErrorDto,
  errorMessage: string
): Promise<T> => {
  if (result instanceof Error) {
    errorCallbackMethod(HttpStatusCode.InternalServerError, errorMessage);
    return Promise.reject(result);
  }
  return result;
};

const getAvailableSeason = async (
  errorCallbackMethod: (status: number, message: string) => ErrorDto,
  tournament?: string,
  seasonFilter?: string
): Promise<SelectionDto> => {
  const query = createQuery(seasonFilter);
  const result = await fetchResult<SelectionDto>(
    `metadata/available-seasons/${tournament}`,
    query
  );

  return handleApiError(
    result,
    errorCallbackMethod,
    "failed getAvailableSeason"
  );
};

const getAvailableTournaments = async (
  errorCallbackMethod: (status: number, message: string) => ErrorDto
): Promise<TournamentDto[]> => {
  const result = await fetchResult<TournamentDto[]>(
    "metadata/available-tournaments",
    ""
  );

  return handleApiError(
    result,
    errorCallbackMethod,
    "failed getAvailableTournaments"
  );
};

const getRankingTable = async (
  errorCallbackMethod: (status: number, message: string) => ErrorDto,
  tournament?: string,
  seasonFilter?: string,
  phaseFilter?: string,
  stageFilter?: string
): Promise<RankingTableResponse> => {
  const query = createQuery(seasonFilter, phaseFilter, stageFilter);
  const result = await fetchResult<RankingTableResponse>(
    `${tournament}/tabelle`,
    query
  );

  return handleApiError(result, errorCallbackMethod, "failed getRankingTable");
};

const getRankingTablePreview = async (
  errorCallbackMethod: (status: number, message: string) => ErrorDto,
  tournament?: string,
  seasonFilter?: string,
  phaseFilter?: string,
  stageFilter?: string
): Promise<RankingTablePreviewResponse> => {
  const query = createQuery(seasonFilter, phaseFilter, stageFilter);
  const result = await fetchResult<RankingTablePreviewResponse>(
    `${tournament}/tabelle/vorschau`,
    query
  );

  return handleApiError(result, errorCallbackMethod, "failed getRankingTable");
};

const getGameDay = async (
  errorCallbackMethod: (status: number, message: string) => ErrorDto,
  tournament?: string,
  seasonFilter?: string,
  phaseFilter?: string,
  stageFilter?: string,
  gameDayFilter?: string,
  showGameDayName: ShowGameDay = ShowGameDay.Current
): Promise<GameDayResponseDto> => {
  const query = createQuery(
    seasonFilter,
    phaseFilter,
    stageFilter,
    gameDayFilter,
    showGameDayName
  );
  const result = await fetchResult<GameDayResponseDto>(
    `${tournament}/spieltag`,
    query
  );

  return handleApiError(result, errorCallbackMethod, "failed getGameDay");
};

export {
  getAvailableSeason,
  getAvailableTournaments,
  getRankingTable,
  getRankingTablePreview,
  getGameDay,
};
