import styled, { css } from "styled-components";

export const GameListHeader = styled.h3`
  ${({ theme: { colors } }) => css`
    margin: 0;
    font-size: 0.75rem;
    line-height: 0.9rem;
    padding: 0.25rem 0;
    color: ${colors.textSecondary};
    text-transform: uppercase;
    font-family: Ringside Narrow, Cambria, Times, sans-serif;
  `};
`;
