import styled, { css } from "styled-components";

type Props = {
  noMargin?: boolean;
};

export const P = styled.p<Props>`
  line-height: 32px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  ${({ noMargin }) =>
    noMargin
      ? css`
          margin-block-start: 0;
          margin-block-end: 0;
        `
      : css`
          margin-block-start: 1em;
          margin-block-end: 1em;
        `}
`;

export const StatisticsText = styled.p`
  ${({ theme: { colors } }) => css`
    font-family: "Ringside Narrow", "Cambria", Times, sans-serif;
    color: ${colors.textSecondary};
    font-size: 0.8rem;
    line-height: 1.2;
    margin: 1rem;
    text-align: center;
    max-width: 700px;
  `};
`;
