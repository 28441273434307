import { DefaultTheme } from "styled-components";
import Color from "./color-palette";

export const lightTheme: DefaultTheme = {
  colors: {
    red1: "#CC0000",
    red2: "#AE0000",
    red3: "#700500",
    red4: "#440000",
    yellow: "#FAD000",
    blue1: "#4D98C9",
    green: "#1FA300",
    white: "#FFFFFF",
    black: "#000000",
    grey0: "#FCFCFC",
    grey1: "#F6F6F6",
    grey2: "#F3F3F3",
    grey3: "#DEDFE0",
    grey4: "#D4D4D5",
    grey5: "#B4B4B4",
    grey6: "#747577",
    grey7: "#595959",
    grey8: "#363535",
    grey9: "#222222",
    brandPrimary: Color.eSportBlue,
    backgroundPrimary: Color.white,
    backgroundPrimaryShadowed: Color.grey0,
    textPrimary: Color.black,
    textSecondary: Color.grey7,
    textTertiary: Color.white,
    borderPrimary: Color.grey3,
    borderSecondary: Color.grey1,
    borderTertiary: Color.grey2,
    scoreBoardBgPrimary: Color.grey8,
    scoreBoardBgSecondary: Color.grey3,
    paginationLink: Color.grey6,
    buttonBgPrimary: Color.grey1,
    buttonBgPrimaryHov: Color.cGrey1,
    buttonBorderPrimaryHov: Color.cGrey2,
    dropdownSelectBg: Color.white,
  },
  breakpoints: {
    xxs: "320px",
    xs: "375px",
    s: "425px",
    m: "768px",
    l: "1024px",
    xl: "1440px",
  },
  over: (breakpoint) =>
    `@media screen and (min-width: calc(${breakpoint} + 1px))`,
  under: (breakpoint) => `@media screen and (max-width: ${breakpoint})`,
  between: (breakpointMin, breakpointMax) =>
    `@media screen and (max-width: ${breakpointMax}) and (min-width: calc(${breakpointMin} + 1px))`,
  /* (Safari + MobileSafari >= 14.6) or (All MobileSafari versions) */
  iPhoneOnly: () =>
    "@supports (selector(:nth-child(1 of x))) or (-webkit-touch-callout: none)",
};

export const darkTheme: DefaultTheme = {
  ...lightTheme,
  colors: {
    ...lightTheme.colors,
    backgroundPrimary: Color.grey9,
    backgroundPrimaryShadowed: Color.grey9,
    textPrimary: Color.white,
    textSecondary: Color.white,
    textTertiary: Color.black,
    borderPrimary: Color.grey6,
    borderSecondary: Color.grey8,
    borderTertiary: Color.grey7,
    scoreBoardBgPrimary: Color.black,
    scoreBoardBgSecondary: Color.grey6,
    paginationLink: Color.grey3,
    buttonBgPrimary: Color.grey8,
    buttonBgPrimaryHov: Color.grey7,
    buttonBorderPrimaryHov: Color.grey7,
    dropdownSelectBg: Color.black,
  },
};
