import { createContext } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import CallbackMethodsDto from "./types/callbacks/CallbackMethodsDto";
import GameDayPage from "./pages/GameDayPage";
import RankingTablePage from "./pages/RankingTablePage";
import TournamentPage from "./pages/TournamentsPage";
import ErrorHandlingProvider from "./features/error-handling/ErrorHandlingProvider";
import ErrorFallback from "./features/error-handling/ErrorFallback";

const CallbacksContext = createContext<CallbackMethodsDto | null>(null);

const RouterWrapper = ({
  callbackMethod,
  errorCallbackMethod,
}: CallbackMethodsDto) => (
  <CallbacksContext.Provider value={{ callbackMethod, errorCallbackMethod }}>
    <ErrorHandlingProvider useBugsnag={true}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TournamentPage />}></Route>
          <Route
            path="/:widgetPrefix/wettbewerbe"
            element={<TournamentPage />}
          ></Route>
          <Route
            path="/:widgetPrefix/:tournament/spieltag"
            element={<GameDayPage />}
          >
            <Route
              path="/:widgetPrefix/:tournament/spieltag"
              element={<GameDayPage />}
            >
              <Route path=":seasonFilter" element={<GameDayPage />}>
                <Route path=":phaseFilter" element={<GameDayPage />}>
                  <Route path=":stageFilter" element={<GameDayPage />}>
                    <Route path=":gameDayFilter" element={<GameDayPage />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          <Route
            path="/:widgetPrefix/:tournament/tabelle"
            element={<RankingTablePage />}
          >
            <Route path=":seasonFilter" element={<RankingTablePage />}>
              <Route path=":phaseFilter" element={<RankingTablePage />}>
                <Route path=":stageFilter" element={<RankingTablePage />} />
              </Route>
            </Route>
          </Route>
          <Route
            path="*"
            element={
              <ErrorFallback
                error={
                  new Error("React Router could not found the requested page")
                }
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </ErrorHandlingProvider>
  </CallbacksContext.Provider>
);

export { CallbacksContext };
export default RouterWrapper;
