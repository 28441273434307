import styled, { css } from "styled-components";

export const AppContainer = styled.div`
  ${({ theme: { colors } }) => css`
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    width: 100%;

    background-color: ${colors.backgroundPrimary};
    color: ${colors.textPrimary};

    font-family: "Tiempos", serif;
    line-height: 1.3;

    * {
      box-sizing: border-box;
    }
  `};
`;

export default AppContainer;
