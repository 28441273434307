import { createRoot } from "react-dom/client";
import CallbackMethodsDto from "./types/callbacks/CallbackMethodsDto";
import { getGameDay } from "./lib/apiCalls";
import App from "./App";
import RouterWrapper from "./RouterWrapper";
import GameDay from "./features/game-day/GameDay/GameDay";

interface Props extends CallbackMethodsDto {
  selector: string;
}

interface GameDayOnlyProps extends CallbackMethodsDto {
  selector: string;
  tournament: string;
  seasonFilter: string;
  stageFilter: string;
  phaseFilter: string;
  gameDayFilter: string;
}

export default {
  eSportWidget: {
    new: () => {
      return {
        render: ({ selector, callbackMethod, errorCallbackMethod }: Props) => {
          const container = document.querySelector(selector);
          if (container !== null) {
            const root = createRoot(container);
            root.render(
              <App>
                <RouterWrapper
                  callbackMethod={callbackMethod}
                  errorCallbackMethod={errorCallbackMethod}
                />
              </App>
            );
          }
        },
      };
    },
  },
  eSportWidgetGameDayOnly: {
    new: () => {
      return {
        render: async ({
          selector,
          tournament,
          seasonFilter,
          stageFilter,
          phaseFilter,
          gameDayFilter,
          errorCallbackMethod,
        }: GameDayOnlyProps) => {
          const gameDayResponse = await getGameDay(
            errorCallbackMethod,
            tournament,
            seasonFilter,
            phaseFilter,
            stageFilter,
            gameDayFilter
          );

          const container = document.querySelector(selector);
          if (container !== null) {
            const root = createRoot(container);
            root.render(
              <App>
                <GameDay data={gameDayResponse} asSubComponent />
              </App>
            );
          }
        },
      };
    },
  },
};
