import { useParams } from "react-router-dom";

export const useBuildUrl = (pathType: "spieltag" | "tabelle") => {
  const { widgetPrefix, tournament, seasonFilter, phaseFilter, stageFilter } =
    useParams<{
      widgetPrefix: string;
      tournament: string;
      seasonFilter?: string;
      phaseFilter?: string;
      stageFilter?: string;
    }>();

  const currentFilters = new Array<string>();

  if (seasonFilter) {
    currentFilters.push(seasonFilter);
    if (phaseFilter) {
      currentFilters.push(phaseFilter);
      if (stageFilter) {
        currentFilters.push(stageFilter);
      }
    }
  }

  return (
    "/" + [widgetPrefix, tournament, pathType].concat(currentFilters).join("/")
  );
};
