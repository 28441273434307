import styled, { css } from "styled-components";
import { HeadBreadCrumbItemSmaller } from "../Headings.styles";

const Dropdown = styled.div`
  position: relative;
`;

export const DropdownBreadCrumb = styled(Dropdown)`
  ${({ theme: { colors } }) => css`
    position: relative;
    color: ${colors.textSecondary};

    &:hover {
      color: ${colors.blue1};
    }
  `};
`;

export const DropdownButton = styled(Dropdown)`
  ${({ theme: { colors } }) => css`
    position: relative;
    color: ${colors.blue1};
    border: 2px solid ${colors.blue1};
    border-radius: 0.1rem;
    padding: 0.125rem 0.5rem 0.15rem;

    &:hover {
      background-color: ${colors.blue1};
      color: ${colors.textTertiary};
    }
  `};
`;

export const DropdownApp = styled(Dropdown)`
  ${({ theme: { colors } }) => css`
    position: relative;
    color: ${colors.brandPrimary};
  `};
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const SignifierBreadCrumb = styled.span`
  font-size: 0.8rem;
  font-weight: 400;

  ${({ theme: { over, breakpoints } }) => css`
    ${over(breakpoints.m)} {
      padding-top: 0.3rem;
    }
  `};
`;

export const SignifierApp = styled.span`
  font-size: 0.8rem;
  font-weight: 400;
`;

export const SignifierButton = styled.span`
  font-size: 0.9rem;
  font-weight: bold;
  padding-left: 1.6rem;
`;

export const LabelButton = styled.span`
  font-family: Ringside Narrow, Cambria, Times, sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  padding: 0;
`;

export const LabelBreadCrumb = styled(HeadBreadCrumbItemSmaller)`
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LabelApp = styled(HeadBreadCrumbItemSmaller)`
  ${({ theme: { colors } }) => css`
    color: ${colors.brandPrimary};
  `};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Select = styled.select`
  ${({ theme: { under, breakpoints, colors } }) => css`
    /* Removes the default <select> styling */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-color: ${colors.dropdownSelectBg};

    & > option {
      font-family: Ringside Narrow, Cambria, Times, sans-serif;
      font-size: 0.9rem;
      outline: none;
      border: none;
      color: ${colors.textSecondary};
    }

    border: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    overflow: hidden;

    ${under(breakpoints.l)} {
      font-size: 16px;
    }
  `};
`;
