import React, { ReactNode } from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

export const notifyError = (errorMessage: string) => {
  Bugsnag.notify(new Error(errorMessage));
};

type ErrorBoundaryProps = {
  children: ReactNode;
  fallbackComponent: any;
};

const ErrorBoundaryWithBugsnag = ({
  children,
  fallbackComponent,
}: ErrorBoundaryProps) => {
  const apiKey = process.env.BUGSNAG_API_KEY;
  const releaseStage = process.env.ENVIRONMENT;
  console.log("releaseStage:", releaseStage);
  const appVersion = process.env.APP_VERSION;

  if (!apiKey || !releaseStage) {
    console.error(
      "Missing Bugsnag API key or ENVIRONMENT in webpack variables"
    );
    return null;
  }

  Bugsnag.start({
    apiKey,
    appVersion,
    releaseStage,
    plugins: [new BugsnagPluginReact()],
    collectUserIp: false,
  });

  const BugsnagsErrorBoundary =
    Bugsnag.getPlugin("react")?.createErrorBoundary(React);

  return BugsnagsErrorBoundary ? (
    <BugsnagsErrorBoundary FallbackComponent={fallbackComponent}>
      {children}
    </BugsnagsErrorBoundary>
  ) : null;
};

export default ErrorBoundaryWithBugsnag;
