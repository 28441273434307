import styled from "styled-components";
import { FlexCol } from "../../../components/FlexBox.styles";

export const RankingTablesFooter = styled(FlexCol)`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const RankingTablesFooterText = styled.p`
  margin: 0;
  padding: 0;
  font-family: Ringside Narrow, Cambria, Times, sans-serif;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 0.8rem;
  line-height: 1.2;
  margin-bottom: 0.25rem;
  max-width: 700px;

  :last-child {
    margin-bottom: 0;
  }
`;
