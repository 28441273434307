import * as S from "./Logo.styles";

type LogoProps = {
  iconName: string;
  label: string;
  size?: number;
  glowOnDarkMode?: boolean;
};

const Logo = ({ iconName, label, size = 30, glowOnDarkMode }: LogoProps) => (
  <S.Image
    src={`https://derivates.kicker.de/image/upload/w_100,q_auto:best/esport/tournament_logos/${iconName}.png`}
    alt={label}
    size={size}
    glowOnDarkMode={glowOnDarkMode}
  />
);

export default Logo;
