import TournamentDto from "../../types/common/TournamentDto";
import Logo from "../Logo/Logo";
import { FlexCol } from "../FlexBox.styles";
import * as S from "./Tournaments.styles";
import { EmbeddedContext } from "../../App";
import { useContext } from "react";

type TournamentsProps = {
  tournaments?: TournamentDto[];
};

const TournamentRowLabel = ({
  iconName,
  label,
  size,
  glowOnDarkMode,
}: {
  iconName: string;
  label: string;
  size: number;
  glowOnDarkMode: boolean;
}) => (
  <S.TournamentsRowLabel alignItems="center" gap="1rem">
    <Logo
      iconName={iconName}
      label={label}
      size={size}
      glowOnDarkMode={glowOnDarkMode}
    />
    <span>{label}</span>
  </S.TournamentsRowLabel>
);

const Tournaments = ({ tournaments }: TournamentsProps) => {
  const embedding = useContext(EmbeddedContext);

  return (
    <FlexCol fluid gap="1rem">
      {tournaments?.map((tournament) => {
        const { key, label, navigation, leagueId, glowOnDarkMode } = tournament;
        const size = embedding?.isInKickerApp ? 25 : 30;

        return (
          <S.TournamentsRow key={key}>
            {embedding?.isInKickerApp ? (
              <S.LinkForApp href={`@@league:[${leagueId}]@@`}>
                <TournamentRowLabel
                  iconName={key}
                  label={label}
                  size={size}
                  glowOnDarkMode={glowOnDarkMode}
                />
              </S.LinkForApp>
            ) : (
              <TournamentRowLabel
                iconName={key}
                label={label}
                size={size}
                glowOnDarkMode={glowOnDarkMode}
              />
            )}

            {!embedding?.isInKickerApp &&
              navigation?.map((navigationElement) => (
                <S.TournamentsRowNavElement key={navigationElement.url}>
                  <a href={navigationElement.url}>{navigationElement.label}</a>
                </S.TournamentsRowNavElement>
              ))}
          </S.TournamentsRow>
        );
      })}
    </FlexCol>
  );
};

export default Tournaments;
