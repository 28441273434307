import ShowGameDay from "../types/enums/showGameDayEnum";

const createQuery = (
  seasonFilter?: string,
  phaseFilter?: string,
  stageFilter?: string,
  gameDayFilter?: string,
  showGameDay?: ShowGameDay
) => {
  const query = new URLSearchParams();

  if (seasonFilter) {
    query.append("seasonFilter", seasonFilter);
  }

  if (phaseFilter) {
    query.append("phaseFilter", phaseFilter);
  }

  if (stageFilter) {
    query.append("stageFilter", stageFilter);
  }

  if (gameDayFilter) {
    query.append("gameDayFilter", gameDayFilter);
  }

  if (showGameDay) {
    query.append("showGameDay", showGameDay.toString());
  }

  return query.toString();
};

export default createQuery;
