import { de } from "date-fns/locale";
import getWeek from "date-fns/getWeek";
import differenceInMinutes from "date-fns/differenceInMinutes";
import ScoreDto from "../../../types/game-day/ScoreDto";
import FinalScoreDto from "../../../types/game-day/FinalScoreDto";
import MatchStatus from "../../../types/enums/matchStatus";
import { FlexCol } from "../../../components/FlexBox.styles";
import * as S from "./ScoreBoard.styles";

const statusLabelComparison = "Beendet nach vergebenen Sieg";
const statusLabelStr = "gew.";

const getDateString = (date: string): string => {
  let dateString = "";
  const currentDate = new Date();
  const matchDate = new Date(date);

  if (currentDate.toDateString() === matchDate.toDateString()) {
    const minutes = differenceInMinutes(matchDate, currentDate, {
      roundingMethod: "ceil",
    });

    if (minutes <= 60 && minutes > 0) {
      dateString = `in 
      ${minutes} Min.`;
    } else if (minutes > 60) {
      dateString = "heute";
    } else {
      dateString = "live";
    }
  } else if (
    getWeek(currentDate, { locale: de }) === getWeek(matchDate, { locale: de })
  ) {
    dateString = `${matchDate.toLocaleDateString("de-DE", {
      weekday: "short",
    })}.`;
  } else {
    dateString = matchDate.toLocaleDateString("de-DE", {
      month: "2-digit",
      day: "2-digit",
    });
  }

  return dateString;
};

const fillUpScores = (
  scores: ScoreDto[],
  expectedScoresLength: number
): ScoreDto[] => {
  const scoresCopy = [...scores];

  if (scoresCopy.length < expectedScoresLength) {
    for (let i = scoresCopy.length; i < expectedScoresLength; i++) {
      scoresCopy.push({ score: "- : -" });
    }
  }

  return scoresCopy;
};

export type ScoreBoardProps = ScoreBoardForScoresProps & {
  date: string;
};

type ScoreBoardForScoresProps = {
  status: string;
  statusLabel: string;
  finalScore: FinalScoreDto;
  scores: ScoreDto[];
};

const ScoreBoardForScores = ({
  status,
  statusLabel,
  finalScore,
  scores,
}: ScoreBoardForScoresProps) => {
  const subScoresStr = fillUpScores(scores, 3)
    .map((x) => x.score)
    .join(" / ");

  return (
    <S.ScoreBoard>
      <S.ScoreHolder live={status === MatchStatus.Finished ? false : true}>
        {finalScore.score}
      </S.ScoreHolder>
      <S.SubScoreHolder>
        {statusLabel === statusLabelComparison ? statusLabelStr : subScoresStr}
      </S.SubScoreHolder>
    </S.ScoreBoard>
  );
};

const ScoreBoard = (props: ScoreBoardProps) => {
  const { status, statusLabel, date, ...otherProps } = props;
  const dateString = getDateString(date);

  switch (status) {
    case MatchStatus.Finished:
    case MatchStatus.InProgress:
      return (
        <ScoreBoardForScores
          status={status}
          statusLabel={statusLabel}
          {...otherProps}
        />
      );
    case MatchStatus.Deleted:
    case MatchStatus.Interrupted:
      return (
        <S.DateBoard>
          <S.DateHolder>
            {status === MatchStatus.Deleted ? "annull." : "abgebr."}
          </S.DateHolder>
        </S.DateBoard>
      );
    case MatchStatus.NotStarted:
    default:
      if (dateString.includes("Min.")) {
        return (
          <S.ScoreBoard>
            <S.TimeInScoreHolder>
              <FlexCol>{dateString}</FlexCol>
            </S.TimeInScoreHolder>
          </S.ScoreBoard>
        );
      } else if (dateString === "live") {
        return (
          <ScoreBoardForScores
            status={MatchStatus.InProgress}
            statusLabel=""
            {...otherProps}
          />
        );
      } else {
        return (
          <S.DateBoard>
            <S.DateHolder>{dateString}</S.DateHolder>
            <S.TimeHolder>
              {new Date(date).toLocaleTimeString("de-DE", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </S.TimeHolder>
          </S.DateBoard>
        );
      }
  }
};

export default ScoreBoard;
