import styled, { css } from "styled-components";

interface DividerProps {
  orientation?: "horizontal" | "vertical";
}

const Divider = styled.div<DividerProps>`
  ${(props) =>
    props.orientation === "vertical"
      ? css`
          border-left: 1px solid ${props.theme.colors.borderPrimary};
          width: 0px;
        `
      : css`
          border-top: 1px solid ${props.theme.colors.borderPrimary};
          width: 100%;
          height: 0px;
        `}
`;

export default Divider;
