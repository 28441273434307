import styled, { css } from "styled-components";

type StylingProps = {
  uppercase?: boolean;
  dontShowOnMobile?: boolean;
};

const baseStyling = css<StylingProps>`
  margin: 0;
  padding: 0;

  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  ${({ dontShowOnMobile }) =>
    dontShowOnMobile &&
    css`
      @media (max-width: 768px) {
        display: none;
      }
    `}
`;

const defaultFont = css`
  font-family: "Knockout 50", Microsoft Sans Serif, Impact, Arial Narrow,
    sans-serif;
  font-weight: 400;
`;

export const H1 = styled.h1`
  ${defaultFont}
  ${baseStyling}

  display: inline-flex;
  flex-wrap: nowrap;
  font-size: 1.7rem;
  align-items: baseline;
`;

export const H2 = styled.h2`
  ${defaultFont}
  ${baseStyling}

  font-size: 1.1rem;
  line-height: 1.2;
  letter-spacing: 0.025em;
  display: flex;
  align-content: center;
`;

export const H3 = styled.h3`
  ${baseStyling}

  font-family: "Knockout 29", Microsoft Sans Serif, Arial Narrow, sans-serif;
  font-size: 1.1rem;
  line-height: 1.2;
  padding-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.brandPrimary};
`;

export const HeadBreadCrumbItemSmaller = styled.span`
  font-family: Ringside Compressed, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  display: flex;

  ${({ theme: { colors, over, breakpoints } }) => css`
    color: ${colors.textSecondary};

    ${over(breakpoints.m)} {
      font-size: 1.4rem;
      padding-top: 0.3rem;
    }
  `};
`;
