import styled, { css } from "styled-components";

const LoadingSpinner = styled.div`
  ${({ theme: { colors } }) => css`
    border: 16px solid ${colors.grey3};
    border-top: 16px solid ${colors.brandPrimary};
    border-radius: 50%;
    height: 100px;
    width: 100px;
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  `};
`;

export default LoadingSpinner;
