const titleCase = (str: string) => {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

const getLabelFromParameter = (str: string) => {
  return titleCase(
    str
      .replaceAll("ue", "ü")
      .replaceAll("ae", "ä")
      .replaceAll("oe", "ä")
      .replaceAll("-", " ")
  );
};

const getParameterFromLabel = (str: string) => {
  return titleCase(
    str
      .replaceAll("ü", "ue")
      .replaceAll("ä", "ae")
      .replaceAll("ä", "oe")
      .replaceAll(" ", "-")
  );
};

export { getLabelFromParameter, getParameterFromLabel };
