import { useState } from "react";
import * as S from "./TeamImage.styles";

type Props = {
  iconName: string;
  size: number;
  label: string;
};

const TeamImage = ({ iconName, size, label }: Props) => {
  const imgPath =
    "https://derivates.kicker.de/image/upload/h_30,w_30,c_fit,q_auto:best/esport/team_logos/enet_pulse/";
  const imgSuffix = ".png";

  const [src, setSrc] = useState(`${imgPath}${iconName}${imgSuffix}`);
  const [alt, setAlt] = useState(`Logo von ${label}`);

  return iconName ? (
    <S.TeamImageContainer size={size}>
      <S.TeamImage
        src={src}
        alt={alt}
        onError={() => {
          setSrc(
            "https://derivates.kicker.de/image/upload/h_30,w_30,c_fit,q_auto:best/esport/common/kicker_esport.png"
          );
          setAlt("Logo von kicker eSport");
        }}
        size={size}
      />
    </S.TeamImageContainer>
  ) : null;
};

export default TeamImage;
