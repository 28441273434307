import styled from "styled-components";

type ModuleProps = {
  size?: "small" | "medium";
};

const Module = styled.div<ModuleProps>`
  width: 100%;
  margin-bottom: ${({ size }) => {
    switch (size) {
      case "small":
        return "0.5rem";
      case "medium":
      default:
        return "1rem";
    }
  }};
`;

export default Module;
