import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TournamentDto from "../types/common/TournamentDto";
import { getAvailableTournaments } from "../lib/apiCalls";
import { CallbacksContext } from "../RouterWrapper";
import PageBody from "../components/PageBody";
import PageHeader from "../components/PageHeader/PageHeader";
import Tournaments from "../components/Tournaments/Tournaments";
import { FlexRow } from "../components/FlexBox.styles";
import LoadingSpinner from "../components/LoadingSpinner.styles";
import ErrorFallback from "../features/error-handling/ErrorFallback";
import { EmbeddedContext } from "../App";

const TournamentPage = () => {
  const callbacks = useContext(CallbacksContext);
  const embedding = useContext(EmbeddedContext);

  const [pageState, setPageState] = useState({
    init: true,
    availableTournaments: {} as TournamentDto[],
    isLoading: true,
    error: null as Error | null,
  });

  const { init, availableTournaments, isLoading, error } = pageState;
  const { widgetPrefix } = useParams<{ widgetPrefix: string }>();

  useEffect(() => {
    const fetchData = async () => {
      setPageState((prev) => ({ ...prev, isLoading: true }));

      try {
        if (callbacks !== null) {
          const { callbackMethod, errorCallbackMethod } = callbacks;

          const fetchedTournaments = await getAvailableTournaments(
            errorCallbackMethod
          );

          callbackMethod("esport-widget", init ? "page-init" : "page-change", [
            { name: "prefix", value: widgetPrefix, label: "eSport" },
            { name: "page", value: "wettbewerbe", label: "Wettbewerbe" },
          ]);

          setPageState((prev) => ({
            ...prev,
            availableTournaments: fetchedTournaments,
            init: false,
            isLoading: false,
          }));
        }
      } catch (err) {
        setPageState((prev) => ({
          ...prev,
          error:
            err instanceof Error ? err : new Error("An unknown error occurred"),
        }));
      }
    };

    fetchData();
  }, [callbacks, init, widgetPrefix]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      {!embedding?.isInKickerApp && (
        <PageHeader>
          <PageHeader.Title
            titleItemForDesktop="eSport"
            titleItem="Wettbewerbe"
          />
        </PageHeader>
      )}
      <PageBody>
        <PageBody.Section>
          {isLoading ? (
            <FlexRow fluid padding alignItems="center" justifyContent="center">
              <LoadingSpinner />
            </FlexRow>
          ) : (
            <Tournaments tournaments={availableTournaments} />
          )}
        </PageBody.Section>
      </PageBody>
    </>
  );
};

export default TournamentPage;
