import styled, { css } from "styled-components";

type Props = {
  size: number;
};

export const TeamImageContainer = styled.div<Props>`
  ${({ size }) =>
    size &&
    css`
      width: ${size}px;
      height: ${size}px;
    `}
`;

export const TeamImage = styled.img<Props>`
  ${({ size }) =>
    size &&
    css`
      width: ${size}px;
      height: ${size}px;
    `}
`;
