import { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import RankingTableRowDto from "../../../types/ranking-table/RankingTableRowDto";
import { getParameterFromLabel } from "../../../lib/text-helper";
import { useBuildUrl } from "../../../hooks/useBuildUrl";
import { EmbeddedContext } from "../../../App";
import TeamImage from "../../../components/TeamImage/TeamImage";
import { AnchorLink } from "../../../components/Link/Link";
import { FlexRow } from "../../../components/FlexBox.styles";
import * as S from "./RankingTable.styles";

type Props = {
  name: string;
  rows: RankingTableRowDto[];
  firstAdvancementTreshold?: number;
  secondAdvancementTreshold?: number;
  smallVariant?: boolean;
};

const RankingTable = ({
  name,
  rows,
  firstAdvancementTreshold = 0,
  secondAdvancementTreshold = 0,
  smallVariant,
}: Props) => {
  const embedding = useContext(EmbeddedContext);
  const inApp = embedding?.isInKickerApp;

  const tableAnchor = getParameterFromLabel(name).toLowerCase();
  const tableUrl = useBuildUrl("tabelle") + `#${tableAnchor}`;

  return rows.length > 0 ? (
    <>
      <FlexRow fluid justifyContent={inApp ? "center" : "flex-start"}>
        {inApp ? (
          <S.TableHeadlineApp id={tableAnchor}>{name}</S.TableHeadlineApp>
        ) : (
          <S.TableHeadlineWeb id={tableAnchor} uppercase>
            {name}
          </S.TableHeadlineWeb>
        )}
      </FlexRow>
      <S.TableContainer>
        <S.RankingTable>
          <thead>
            <tr>
              <S.HeadCell upperCase={!inApp}>
                {inApp ? "Platz" : "Pl."}
              </S.HeadCell>
              <S.HeadCell></S.HeadCell>
              <S.HeadCell alignLeft sticky upperCase={!inApp}>
                Team
              </S.HeadCell>
              {!smallVariant && (
                <S.HeadCell hideOnDesktop upperCase={!inApp}>
                  Punkte
                </S.HeadCell>
              )}
              <S.HeadCell upperCase={!inApp}>Sp.</S.HeadCell>
              {!smallVariant && !inApp && (
                <>
                  <S.HeadCell hideOnMobile upperCase={!inApp}>
                    S
                  </S.HeadCell>
                  <S.HeadCell hideOnMobile upperCase={!inApp}>
                    U
                  </S.HeadCell>
                  <S.HeadCell hideOnMobile upperCase={!inApp}>
                    N
                  </S.HeadCell>
                  <S.HeadCell hideOnDesktop upperCase={!inApp}>
                    S-U-N
                  </S.HeadCell>
                  <S.HeadCell upperCase={!inApp}>Tore</S.HeadCell>
                </>
              )}
              <S.HeadCell upperCase={!inApp}>Diff.</S.HeadCell>
              <S.HeadCell
                hideOnMobile={!smallVariant ? true : false}
                upperCase={!inApp}
              >
                Punkte
              </S.HeadCell>
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows?.map((row: RankingTableRowDto, index) => {
                const {
                  rank,
                  participant,
                  games,
                  wins,
                  draws,
                  defeats,
                  goalsScored,
                  goalsReceived,
                  difference,
                  points,
                } = row;

                return (
                  <S.Row
                    key={uuidv4()}
                    greyBg={index % 2 === 0}
                    treshholdBorder={
                      index === firstAdvancementTreshold - 1 ||
                      index === secondAdvancementTreshold - 1
                    }
                  >
                    <S.Cell bolder>{rank}</S.Cell>
                    <S.Cell>
                      {participant.showIcon === true && (
                        <TeamImage
                          iconName={participant.iconName}
                          size={30}
                          label={participant.label}
                        />
                      )}
                    </S.Cell>
                    <S.Cell alignLeft bolder sticky>
                      {participant.label}
                    </S.Cell>
                    {!smallVariant && (
                      <S.NumberCell bolder color="brand" hideOnDesktop>
                        {points}
                      </S.NumberCell>
                    )}
                    <S.NumberCell color="grey">{games}</S.NumberCell>
                    {!smallVariant && !inApp && (
                      <>
                        <S.NumberCell color="grey" hideOnMobile>
                          {wins}
                        </S.NumberCell>
                        <S.NumberCell color="grey" hideOnMobile>
                          {draws}
                        </S.NumberCell>
                        <S.NumberCell color="grey" hideOnMobile>
                          {defeats}
                        </S.NumberCell>
                        <S.NumberCell color="grey" hideOnDesktop>
                          {wins}-{draws}-{defeats}
                        </S.NumberCell>
                        <S.NumberCell color="grey">{`${goalsScored}:${goalsReceived}`}</S.NumberCell>
                      </>
                    )}
                    <S.NumberCell color="grey">{difference}</S.NumberCell>
                    <S.NumberCell
                      bolder
                      color="brand"
                      hideOnMobile={!smallVariant ? true : false}
                    >
                      {points}
                    </S.NumberCell>
                  </S.Row>
                );
              })}
          </tbody>
        </S.RankingTable>
      </S.TableContainer>
      {smallVariant && <AnchorLink to={tableUrl}>Tabelle - {name}</AnchorLink>}
    </>
  ) : null;
};

export default RankingTable;
