import { PropsWithChildren, useContext } from "react";
import { EmbeddedContext } from "../../../App";
import ShowGameDay from "../../../types/enums/showGameDayEnum";
import Divider from "../../../components/Divider.styles";
import { FlexRow } from "../../../components/FlexBox.styles";
import * as S from "./Pagination.styles";

type PaginationProps = PropsWithChildren & {
  tournament: string;
  season: string;
  selectedGameDay: string;
  availableGameDays: string[];
  availablePhases: string[];
  selectedPhase: string;
  selectedStage: string;
};

type CommonPaginationProps = { type: "next" | "previous" };

type PaginationButtonProps = CommonPaginationProps & {
  url?: string;
};

type PaginationLinkProps = PropsWithChildren &
  CommonPaginationProps & {
    url: string;
  };

const PaginationLink = ({ type, url, children }: PaginationLinkProps) => (
  <S.PaginationLink
    to={url}
    state={
      type === "next"
        ? { showGameDay: ShowGameDay.First }
        : { showGameDay: ShowGameDay.Last }
    }
  >
    {children}
  </S.PaginationLink>
);

const iconClassMap = {
  next: {
    app: "icon-Pfeil01 kick__icon-Pfeil01",
    web: "icon-Pfeil04 kick__icon-Pfeil04",
  },
  previous: {
    app: "icon-Pfeil02 kick__icon-Pfeil02",
    web: "icon-Pfeil03 kick__icon-Pfeil03",
  },
};

const AppPaginationButton = ({ type, url }: PaginationButtonProps) =>
  url ? (
    <PaginationLink type={type} url={url}>
      <S.AppPaginationIcon className={iconClassMap[type].app} />
    </PaginationLink>
  ) : (
    <S.AppPaginationIcon grayedOut className={iconClassMap[type].app} />
  );

const WebPaginationButton = ({ type, url }: PaginationLinkProps) => (
  <PaginationLink type={type} url={url}>
    {type === "next" && "nächster Spieltag"}
    <S.WebPaginationIcon className={iconClassMap[type].web} />
    {type === "previous" && "vorheriger Spieltag"}
  </PaginationLink>
);

const Pagination = ({
  tournament,
  season,
  selectedGameDay,
  availableGameDays,
  availablePhases,
  selectedPhase,
  selectedStage,
  children,
}: PaginationProps) => {
  const getIndex = (list: string[], selected: string): number => {
    return list.findIndex((x) => x === selected);
  };

  const getNextUrl = (): string | undefined => {
    const currentGameDayIndex = availableGameDays.findIndex(
      (x) => x === selectedGameDay
    );

    if (currentGameDayIndex < availableGameDays.length - 1) {
      return `/esport/${tournament}/spieltag/${season}/${selectedPhase}/${selectedStage}/${
        availableGameDays[currentGameDayIndex + 1]
      }`;
    } else {
      const currentPhaseIndex = getIndex(availablePhases, selectedPhase);
      if (currentPhaseIndex < availablePhases.length - 1) {
        return `/esport/${tournament}/spieltag/${season}/${
          availablePhases[currentPhaseIndex + 1]
        }`;
      }
      return undefined;
    }
  };

  const getPreviousUrl = (): string | undefined => {
    const currentGameDayIndex = availableGameDays.findIndex(
      (x) => x === selectedGameDay
    );

    if (currentGameDayIndex >= 1) {
      return `/esport/${tournament}/spieltag/${season}/${selectedPhase}/${selectedStage}/${
        availableGameDays[currentGameDayIndex - 1]
      }`;
    } else {
      const currentPhaseIndex = getIndex(availablePhases, selectedPhase);
      if (currentPhaseIndex >= 1) {
        return `/esport/${tournament}/spieltag/${season}/${
          availablePhases[currentPhaseIndex - 1]
        }`;
      }
      return undefined;
    }
  };

  const previousUrl = getPreviousUrl();
  const nextUrl = getNextUrl();

  const embedding = useContext(EmbeddedContext);
  const inApp = embedding?.isInKickerApp;

  let align: "left" | "right" | undefined;
  if (!inApp && !previousUrl && nextUrl) align = "right";
  if (!inApp && !nextUrl && previousUrl) align = "left";

  return (
    <S.PaginationContainer align={align}>
      {inApp ? (
        <FlexRow gap="0.5rem">
          <AppPaginationButton type="previous" url={previousUrl} />
          <Divider orientation="vertical" />
        </FlexRow>
      ) : previousUrl ? (
        <div>
          <WebPaginationButton type="previous" url={previousUrl} />
        </div>
      ) : null}

      {inApp && children}

      {inApp ? (
        <FlexRow gap="0.5rem">
          <Divider orientation="vertical" />
          <AppPaginationButton type="next" url={nextUrl} />
        </FlexRow>
      ) : nextUrl ? (
        <div>
          <WebPaginationButton type="next" url={nextUrl} />
        </div>
      ) : null}
    </S.PaginationContainer>
  );
};

export default Pagination;
