import axios, { AxiosRequestConfig } from "axios";
import logError from "./logError";

const config: AxiosRequestConfig = {
  baseURL: process.env.BACKEND_URL,
};
const instance = axios.create(config);

const fetchBffApi = async <T>(url: string, queryParams: string): Promise<T> => {
  try {
    const queryParamExtension = queryParams ? `?${queryParams}` : "";
    const response = await instance.get(url + queryParamExtension);
    return response.data as T;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        logError(new Error(`Message: ${error.message}`));
      } else if (error.request) {
        logError(
          new Error("The request was made, but no response was received.")
        );
      } else {
        logError(new Error("Something went wrong with the request setup."));
      }
    } else {
      logError(new Error("An unknown axios error occurred."));
    }
    return Promise.reject();
  }
};

export default fetchBffApi;
