import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import RankingTableResponse from "../types/ranking-table/RankingTableResponse";
import GameDayResponseDto from "../types/game-day/GameDayResponseDto";
import SelectionDto from "../types/common/SelectionDto";
import { getLabelFromParameter } from "../lib/text-helper";
import {
  getAvailableSeason,
  getGameDay,
  getRankingTable,
} from "../lib/apiCalls";
import { EmbeddedContext } from "../App";
import { CallbacksContext } from "../RouterWrapper";
import PageHeader from "../components/PageHeader/PageHeader";
import DropDown from "../components/DropDown/DropDown";
import { HeadlineRow } from "../components/Page.styles";
import { FlexCol, FlexRow } from "../components/FlexBox.styles";
import { H2 } from "../components/Headings.styles";
import LoadingSpinner from "../components/LoadingSpinner.styles";
import PageBody from "../components/PageBody";
import Logo from "../components/Logo/Logo";
import RankingTables from "../features/ranking-table/RankingTables/RankingTables";
import GameDay from "../features/game-day/GameDay/GameDay";
import ErrorFallback from "../features/error-handling/ErrorFallback";
import Divider from "../components/Divider.styles";

interface Filters {
  seasonFilter: string | undefined;
  phaseFilter: string | undefined;
  stageFilter: string | undefined;
}

type Params = {
  widgetPrefix?: string;
  tournament?: string;
  seasonFilter?: string;
  stageFilter?: string;
  phaseFilter?: string;
};

type HeaderProps = {
  isLoading: boolean;
  availableSeasons: SelectionDto;
  rtData: RankingTableResponse;
  initParams: Params;
  changeFilter: ChangeFilterFunc;
};

type ChangeFilterFunc = (
  changedSeasonFilter?: string,
  changedPhaseFilter?: string,
  changedStageFilter?: string,
  changedGameDayFilter?: string
) => void;

const AppHeader = ({
  isLoading,
  availableSeasons,
  rtData,
  changeFilter,
}: HeaderProps) => (
  <FlexCol style={{ flexWrap: "nowrap", padding: "0 0.5rem" }}>
    {!isLoading ? (
      <FlexRow
        fluid
        justifyContent="center"
        gap="0.5rem"
        style={{ flexWrap: "nowrap", padding: "0.75rem 0" }}
      >
        <FlexRow fluid>
          {availableSeasons && (
            <DropDown
              variant="app"
              label={availableSeasons.label}
              options={availableSeasons.options}
              selected={rtData.selectedSeason}
              setSelected={(arg) => changeFilter(arg)}
            />
          )}
        </FlexRow>
        <Divider orientation="vertical" />
        <FlexRow fluid justifyContent="flex-end">
          {rtData.availablePhases.options?.length > 0 && (
            <DropDown
              variant="app"
              label={rtData.availablePhases.label}
              options={rtData.availablePhases.options}
              selected={rtData.selectedPhase}
              setSelected={(arg) =>
                changeFilter(rtData.selectedSeason, arg, undefined)
              }
              dataTestId="phase-dropdown"
            />
          )}
        </FlexRow>
      </FlexRow>
    ) : null}
    <Divider />
  </FlexCol>
);

const WebHeader = ({
  isLoading,
  initParams,
  availableSeasons,
  rtData,
  changeFilter,
}: HeaderProps) => (
  <PageHeader>
    {initParams.tournament && (
      <Logo
        iconName={initParams.tournament}
        label={initParams.tournament}
        glowOnDarkMode={rtData.tournamentLogoGlowOnDarkMode}
      />
    )}
    <PageHeader.BreadCrumbsWrapperColRow>
      {rtData.tournamentLabel && (
        <PageHeader.Title
          titleItemForDesktop={rtData.tournamentLabel}
          titleItem="Tabelle"
        />
      )}
      {!isLoading && (
        <PageHeader.BreadCrumbsWrapperRow>
          {availableSeasons && (
            <DropDown
              label={availableSeasons.label}
              options={availableSeasons.options}
              selected={rtData.selectedSeason}
              setSelected={(arg) => changeFilter(arg)}
            />
          )}
          {rtData.availablePhases.options?.length > 0 && (
            <DropDown
              label={rtData.availablePhases.label}
              options={rtData.availablePhases.options}
              selected={rtData.selectedPhase}
              setSelected={(arg) =>
                changeFilter(rtData.selectedSeason, arg, undefined)
              }
              dataTestId="phase-dropdown"
            />
          )}
        </PageHeader.BreadCrumbsWrapperRow>
      )}
    </PageHeader.BreadCrumbsWrapperColRow>
  </PageHeader>
);

const RankingTablePage = () => {
  const callbacks = useContext(CallbacksContext);
  const embedding = useContext(EmbeddedContext);
  const inApp = embedding?.isInKickerApp;
  const navigate = useNavigate();

  const initParams = useParams<Params>();

  const [pageState, setPageState] = useState({
    init: true,
    availableSeasons: {} as SelectionDto,
    gdData: {} as GameDayResponseDto,
    rtData: {} as RankingTableResponse,
    isLoading: true,
    error: null as Error | null,
    filters: {
      seasonFilter: initParams.seasonFilter,
      phaseFilter: initParams.phaseFilter,
      stageFilter: initParams.stageFilter,
    } as Filters,
  });

  const { availableSeasons, gdData, rtData, isLoading, error } = pageState;

  const changeFilter = (
    changedSeasonFilter?: string,
    changedPhaseFilter?: string,
    changedStageFilter?: string
  ) => {
    const currentParams = new Array<string>();
    if (changedSeasonFilter) {
      currentParams.push(changedSeasonFilter);
      if (changedPhaseFilter) {
        currentParams.push(changedPhaseFilter);
        if (changedStageFilter) {
          currentParams.push(changedStageFilter);
        }
      }
    }

    const uri = `/${initParams.widgetPrefix}/${
      initParams.tournament
    }/tabelle/${currentParams.join("/")}`;
    navigate(uri, { replace: true });
  };

  const { seasonFilter, phaseFilter } = pageState.filters;

  useEffect(() => {
    const fetchData = async () => {
      setPageState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      try {
        if (callbacks !== null) {
          const { callbackMethod, errorCallbackMethod } = callbacks;

          const [availableSeasons, gameDayResponse, rankingTableResponse] =
            await Promise.all([
              await getAvailableSeason(
                errorCallbackMethod,
                initParams.tournament,
                initParams.seasonFilter
              ),
              await getGameDay(
                errorCallbackMethod,
                initParams.tournament,
                initParams.seasonFilter,
                initParams.phaseFilter,
                initParams.stageFilter
              ),
              await getRankingTable(
                errorCallbackMethod,
                initParams.tournament,
                initParams.seasonFilter,
                initParams.phaseFilter,
                initParams.stageFilter
              ),
            ]);

          callbackMethod(
            "esport-widget",
            pageState.init ? "page-init" : "page-change",
            [
              {
                name: "prefix",
                value: initParams.widgetPrefix,
                label: "eSport",
              },
              {
                name: "tournament",
                value: rankingTableResponse.tournamentKey,
                label: rankingTableResponse.tournamentLabel,
              },
              { name: "page", value: "tabelle", label: "Tabelle" },
              {
                name: "season",
                value: rankingTableResponse.selectedSeason,
                label: availableSeasons.label,
              },
              {
                name: "phase",
                value: rankingTableResponse.selectedPhase,
                label: rankingTableResponse.availablePhases.label,
              },
              {
                name: "stage",
                value: rankingTableResponse.selectedStage,
                label: rankingTableResponse.availableStages?.label ?? "alle",
              },
            ]
          );

          const newFilters = {
            seasonFilter: gameDayResponse.selectedSeason,
            phaseFilter: gameDayResponse.selectedPhase,
            stageFilter: gameDayResponse.selectedStage,
            gameDayFilter: gameDayResponse.selectedGameDay.gameDayKey,
          };

          setPageState((prev) => ({
            ...prev,
            init: false,
            availableSeasons: availableSeasons,
            gdData: gameDayResponse as GameDayResponseDto,
            rtData: rankingTableResponse as RankingTableResponse,
            isLoading: false,
            filters: newFilters,
          }));
        }
      } catch (err) {
        setPageState((prev) => ({
          ...prev,
          error:
            err instanceof Error ? err : new Error("An unknown error occurred"),
        }));
      }
    };

    fetchData();
  }, [initParams]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      {inApp ? (
        <AppHeader
          isLoading={isLoading}
          availableSeasons={availableSeasons}
          rtData={rtData}
          initParams={initParams}
          changeFilter={changeFilter}
        />
      ) : (
        <WebHeader
          isLoading={isLoading}
          availableSeasons={availableSeasons}
          rtData={rtData}
          initParams={initParams}
          changeFilter={changeFilter}
        />
      )}

      <PageBody>
        <PageBody.Section>
          {!inApp && (
            <HeadlineRow
              fluid
              alignItems="center"
              justifyContent="space-between"
            >
              <H2 uppercase>Tabelle{rtData?.tables?.length > 1 && "n"}</H2>
              {rtData.availableStages?.options?.length > 1 && (
                <DropDown
                  variant="button"
                  label={rtData.availableStages.label}
                  options={rtData.availableStages.options}
                  selected={rtData.selectedStage}
                  setSelected={(arg) =>
                    changeFilter(seasonFilter, phaseFilter, arg)
                  }
                  dataTestId="divisions-dropdown"
                />
              )}
            </HeadlineRow>
          )}

          <FlexCol fluid alignItems="flex-start">
            {isLoading ? (
              <FlexRow
                fluid
                padding
                alignItems="center"
                justifyContent="center"
              >
                <LoadingSpinner />
              </FlexRow>
            ) : (
              <RankingTables data={rtData} />
            )}
          </FlexCol>
        </PageBody.Section>
        {!inApp && (
          <PageBody.Aside>
            <FlexCol fluid>
              <FlexRow fluid justifyContent="flex-start">
                <H2 uppercase>Aktuelle Begegnungen</H2>
              </FlexRow>
              {isLoading ? (
                <FlexRow
                  fluid
                  padding
                  alignItems="center"
                  justifyContent="center"
                >
                  <LoadingSpinner />
                </FlexRow>
              ) : (
                <GameDay data={gdData} asSubComponent />
              )}
            </FlexCol>
          </PageBody.Aside>
        )}
      </PageBody>
    </>
  );
};

export default RankingTablePage;
