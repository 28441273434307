import { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import GameListDto from "../../../types/game-day/GameListDto";
import GameRowDto from "../../../types/game-day/GameRowDto";
import MatchStatus from "../../../types/enums/matchStatus";
import WinnerStatus from "../../../types/enums/winnerStatus";
import GameRowVisualType from "../../../types/enums/gameRowVisualType";
import { FlexRow, FlexCol } from "../../../components/FlexBox.styles";
import TeamImage from "../../../components/TeamImage/TeamImage";
import GameRow from "../GameRow/GameRow";
import ScoreBoard from "../ScoreBoard/ScoreBoard";
import { EmbeddedContext } from "../../../App";
import * as S from "./GameList.styles";

const getGameRowFlags = (visualType: GameRowVisualType) => {
  switch (visualType) {
    case GameRowVisualType.StandardKo:
      return {
        grayOutLoser: true,
      };
    case GameRowVisualType.Standard:
    default:
      return {
        grayOutLoser: false,
      };
  }
};

type GameListProps = {
  gameList: GameListDto;
  asSubComponent?: boolean;
  withFoldingScoreBoard: boolean;
};

type CompoundedGameRowProps = {
  match: GameRowDto;
  index: number;
  asSubComponent?: boolean;
};

const CompoundedGameRow = ({
  match,
  asSubComponent,
  index,
}: CompoundedGameRowProps) => {
  const {
    visualType,
    status,
    label: statusLabel,
    stageLabel,
    finalScore,
    scores,
    date,
    leftParticipant,
    rightParticipant,
  } = match;

  const { grayOutLoser } = getGameRowFlags(visualType);

  const withGrayOutLoser =
    grayOutLoser &&
    status === MatchStatus.Finished &&
    finalScore.score !== WinnerStatus.Draw;

  const withGroupLabel = stageLabel && !asSubComponent;

  const teamImageSize = 25;

  return (
    <GameRow greyBg={index % 2 == 1}>
      <FlexCol>
        <FlexRow
          fluid
          justifyContent="center"
          alignItems="center"
          gap={"0.5rem"}
        >
          {withGroupLabel && (
            <GameRow.GroupLabel>{stageLabel}</GameRow.GroupLabel>
          )}
          <GameRow.TeamContainer alignRight>
            <GameRow.TeamTitle
              participant={leftParticipant}
              asSubComponent={!!asSubComponent}
              grayOut={withGrayOutLoser && finalScore.score !== "W : L"}
            />
            {leftParticipant.showIcon && (
              <TeamImage
                iconName={leftParticipant.iconName}
                size={teamImageSize}
                label={leftParticipant.label}
              />
            )}
          </GameRow.TeamContainer>
          <ScoreBoard
            status={status}
            statusLabel={statusLabel}
            finalScore={finalScore}
            scores={scores}
            date={date}
          />
          <GameRow.TeamContainer>
            {rightParticipant.showIcon && (
              <TeamImage
                iconName={rightParticipant.iconName}
                size={teamImageSize}
                label={rightParticipant.label}
              />
            )}
            <GameRow.TeamTitle
              participant={rightParticipant}
              asSubComponent={!!asSubComponent}
              grayOut={withGrayOutLoser && finalScore.score !== "L : W"}
            />
          </GameRow.TeamContainer>
          {withGroupLabel && <GameRow.GroupLabel />}
        </FlexRow>
      </FlexCol>
    </GameRow>
  );
};

const GameList = ({ gameList, asSubComponent }: GameListProps) => {
  const embedding = useContext(EmbeddedContext);
  const inApp = embedding?.isInKickerApp;
  const { label, gameRows } = gameList;

  return (
    <FlexCol fluid>
      <FlexRow fluid justifyContent={inApp ? "center" : "flex-start"}>
        <S.GameListHeader>{label}</S.GameListHeader>
      </FlexRow>
      {gameRows &&
        gameRows?.map((gameRow: GameRowDto, index) => (
          <CompoundedGameRow
            key={uuidv4()}
            match={gameRow}
            index={index}
            asSubComponent={asSubComponent}
          />
        ))}
    </FlexCol>
  );
};

export default GameList;
