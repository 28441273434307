import RankingTableResponse from "../../../types/ranking-table/RankingTableResponse";
import { FlexCol } from "../../../components/FlexBox.styles";
import { StatisticsText } from "../../../components/Text.styles";
import RankingTable from "../RankingTable/RankingTable";
import * as S from "./RankingTables.styles";

type Props = {
  data: RankingTableResponse;
  smallVariant?: boolean;
};

const RankingTables = ({ data, smallVariant }: Props) => {
  const currentDateString = new Date()
    .toLocaleDateString("de-DE", {
      weekday: "short",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
    .replaceAll(",", "");

  return (
    <>
      {data?.tables?.length > 0 &&
      data?.tables?.every((table) => table.rows.length > 0) ? (
        <>
          {data.tables.map((table) => (
            <RankingTable
              smallVariant={smallVariant}
              name={table.name}
              rows={table.rows}
              key={table.name}
              firstAdvancementTreshold={table.firstAdvancementTreshold}
              secondAdvancementTreshold={table.secondAdvancementTreshold}
            />
          ))}
          <S.RankingTablesFooter>
            <S.RankingTablesFooterText>
              {`Stand: ${currentDateString}`}
            </S.RankingTablesFooterText>
            <S.RankingTablesFooterText>
              {data.tables[0].description}
            </S.RankingTablesFooterText>
          </S.RankingTablesFooter>
        </>
      ) : (
        <FlexCol fluid>
          <StatisticsText>
            {data.message ? (
              <>{data.message}</>
            ) : (
              <>
                Aktuell stehen für den gewünschten Bereich noch keine Daten zur
                Verfügung.
              </>
            )}
          </StatisticsText>
        </FlexCol>
      )}
    </>
  );
};

export default RankingTables;
