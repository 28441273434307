import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

type PaginationContainerProps = {
  align?: "left" | "right";
};

type AppPaginationIconProps = {
  grayedOut?: boolean;
};

export const PaginationContainer = styled.div<PaginationContainerProps>`
  display: flex;
  flex-wrap: nowrap;
  justify-content: ${({ align }) => {
    switch (align) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "space-between";
    }
  }};
  align-items: center;
`;

export const PaginationLink = styled(Link)`
  font-family: Ringside Narrow, Cambria, Times, sans-serif;
  font-size: 0.8rem;
  line-height: 1.2;
  min-width: 1.2rem;
  display: flex;
  gap: 0.25rem;
  padding: 0.125rem 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  vertical-align: baseline;
  text-decoration: none;

  ${({ theme }) => css`
    color: ${theme.colors.paginationLink};

    &:hover {
      color: ${theme.colors.brandPrimary};
    }
  `};
`;

export const AppPaginationIcon = styled.span<AppPaginationIconProps>`
  font-size: 1.25rem;
  color: ${({ theme, grayedOut }) =>
    grayedOut ? theme.colors.borderPrimary : theme.colors.brandPrimary};
`;

export const WebPaginationIcon = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.brandPrimary};

  &:hover {
    text-decoration: none;
    font-weight: normal;
  }
`;
