import styled, { css } from "styled-components";

type ImageProps = {
  size: number;
  glowOnDarkMode?: boolean;
};

export const Image = styled.img<ImageProps>`
  ${({ theme: { over, breakpoints }, size, glowOnDarkMode }) => css`
    width: ${`${size}px`};
    height: ${`${size}px`};

    ${over(breakpoints.m)} {
      width: ${`${size + 10}px`};
      height: ${`${size + 10}px`};
    }

    ${glowOnDarkMode &&
    css`
      @media (prefers-color-scheme: dark) {
        filter: drop-shadow(0 0 1px #b4b4b4) drop-shadow(0 0 1px #b4b4b4)
          drop-shadow(0 0 1px #b4b4b4) drop-shadow(0 0 1px #b4b4b4);
      }
    `}
  `};
`;
