import * as S from "./Link.styles";

type BaseLinkProps = {
  children: React.ReactNode;
  to: string;
};

type LinkProps = BaseLinkProps & {
  variant: "default" | "button";
  reloadDocument?: boolean;
  className?: string;
};

export const Link = ({ children, variant, ...other }: LinkProps) =>
  variant === "button" ? (
    <S.ButtonLink {...other}>{children}</S.ButtonLink>
  ) : (
    <S.StyledLink {...other}>{children}</S.StyledLink>
  );

export const AnchorLink = ({ children, to }: BaseLinkProps) => (
  <S.ButtonHashLink to={to}>{children}</S.ButtonHashLink>
);
