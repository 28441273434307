enum Color {
  red1 = "#CC0000",
  red2 = "#AE0000",
  red3 = "#700500",
  red4 = "#440000",
  yellow = "#FAD000",
  blue1 = "#4D98C9",
  eSportBlue = "#00B2FF",
  green = "#1FA300",
  white = "#FFFFFF",
  black = "#000000",
  grey0 = "#FCFCFC",
  grey1 = "#F6F6F6",
  grey2 = "#F3F3F3",
  grey3 = "#DEDFE0",
  grey4 = "#D4D4D5",
  grey5 = "#B4B4B4",
  grey6 = "#747577",
  grey7 = "#595959",
  grey8 = "#363535",
  grey9 = "#222222",
  cGrey1 = "#E3E3E3",
  cGrey2 = "#DDDDDD",
}

export default Color;
