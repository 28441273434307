import { ReactNode } from "react";
import * as S from "./PageHeader.styles";

type TitleProps = {
  titleItemForDesktop: string;
  titleItem: string;
};

const PageHeader = ({ children }: { children: ReactNode }) => (
  <S.PageHeader>{children}</S.PageHeader>
);

const Title = ({ titleItemForDesktop, titleItem }: TitleProps) => (
  <S.Title>
    <S.TitleItemForDesktop>{titleItemForDesktop}</S.TitleItemForDesktop>
    {titleItem}
  </S.Title>
);

PageHeader.Title = Title;
PageHeader.BreadCrumbsWrapperColRow = S.BreadCrumbsWrapperColRow;
PageHeader.BreadCrumbsWrapperRow = S.BreadCrumbsWrapperRow;
PageHeader.BreadCrumbsWrapperContainer = S.BreadCrumbsWrapperContainer;
PageHeader.BreadCrumbsWrapperFirstLine = S.BreadCrumbsWrapperFirstLine;
PageHeader.BreadCrumbsWrapperSecondLine = S.BreadCrumbsWrapperSecondLine;
PageHeader.BreadCrumbsWrapperElement = S.BreadCrumbsWrapperElement;

export default PageHeader;
